import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAppSelector } from 'src/redux/store';
import { AnimateAvatar } from 'src/components/animate';
import { alpha, useTheme } from '@mui/material';
import Iconify from 'src/components/iconify';
import { DefaultIcons } from 'src/components/icons/defaults';
import ThemeModeButton from '../../theme-mode-button';


export function AccountGeneralView() {
  const user = useAppSelector(state => state.auth.user);
  const theme = useTheme();



  return (
    <Grid container spacing={3}>
      <Grid xs={12} md={12}>
        <Card
          sx={{
            pt: 10,
            pb: 5,
            px: 3,
            textAlign: 'center',
          }}
        >
          <Stack display='flex' alignItems='center' spacing={2}>

            <AnimateAvatar
              width={96}
              slotProps={{
                avatar: { src: user?.photoURL, alt: user?.displayName },
                overlay: {
                  border: 2,
                  spacing: 3,
                  color: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0)} 25%, ${theme.palette.primary.main} 100%)`,
                },
              }}
            >
              {user?.displayName?.charAt(0).toUpperCase()}
            </AnimateAvatar>
            <ThemeModeButton />

          </Stack>

        </Card>
      </Grid>

      <Grid xs={12} md={12}>
        <Card sx={{ p: 3 }}>
          <Stack spacing={1}>

            {user?.displayName && <Stack direction='row' spacing={2} display='flex' alignItems='center'>
              <Iconify icon={DefaultIcons.name} />
              <Typography>
                {user.displayName}
              </Typography>
            </Stack>}

            {user?.email && <Stack direction='row' spacing={2} display='flex' alignItems='center'>
              <Iconify icon={DefaultIcons.email} />
              <Typography>
                {user.email}
              </Typography>
            </Stack>}

          </Stack>

        </Card>
      </Grid>
    </Grid>
  );
}
