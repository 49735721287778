import { Navigate, useRoutes } from 'react-router-dom';
// auth
import StudioLayout from 'src/layouts/studio';

import PublicLayout from 'src/layouts/public/PublicLayout';
import AuthModernCompactLayout from 'src/layouts/login/modern-compact';
import ResellerGuard from 'src/auth/ResellerGuard';
import WalletCouponsView from 'src/sections/wallet/coupons/wallet-coupons-view';
import ProgramCollectionDetailsPage from 'src/pages/dashboard/program-collection-details-page';
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
// config
import { GlobalConfig, PATH_AFTER_LOGIN } from '../config-global';
//
import {
  Page404,
  LoginPage,
  WalletPage,
  ResetPasswordPage,
  Page500,
  AuthDiscordRedirectHandlerPage,
  AuthCallbackPage,
  // RedeemPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "a/:resellerId",
      element: <ResellerGuard />,
      children: [
        {
          path: '',
          children: [
            { element: <Navigate to="auth" />, index: true },
          ],
        },
        {
          path: 'auth',
          children: [
            // { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            {
              path: '',
              element: (
                <GuestGuard>
                  <AuthModernCompactLayout>
                    <LoginPage />
                  </AuthModernCompactLayout>
                </GuestGuard>
              ),
              index: true
            },
            {
              children: [
                { path: 'reset-password', element: <AuthModernCompactLayout><ResetPasswordPage /> </AuthModernCompactLayout> },
              ],
            },
            {
              path: 'callback', element:
                // <GuestGuard>
                <AuthCallbackPage />
              // </GuestGuard>

            },

          ],
        },
        // {
        //   path: 'public',
        //   element: <PublicLayout />,
        //   children: [
        //     { element: <Navigate to="../public/redeem" />, index: true },
        //     {
        //       path: 'redeem',
        //       element: <RedeemPage />,
        //     },

        //   ],
        // },
        // {
        //   path: 'iframe/public',
        //   children: [
        //     { element: <Navigate to="../iframe/public/redeem" />, index: true },
        //     {
        //       path: 'redeem',
        //       element: <RedeemPage />,
        //     },

        //   ],
        // },
        {
          path: 'wallet',
          element: (
            <AuthGuard>
              <StudioLayout />
            </AuthGuard>
          ),
          children: [
            { element: <Navigate to={`..${PATH_AFTER_LOGIN}`} replace />, index: true },
            {
              path: 'home',
              element: <WalletPage />,
            },
            {
              path: 'collection',
              element: <ProgramCollectionDetailsPage />,
            },

          ],
        },
        {
          element: <CompactLayout />,
          children: [{ path: '404', element: <Page404 /> }],
        },
        {
          element: <CompactLayout />,
          children: [{ path: '500', element: <Page500 /> }],
        },
        { path: '*', element: <Navigate to="../404" replace /> },
      ]
    },
    { path: '/', element: <Navigate to={`/a/${GlobalConfig.DEFAULT_WALLET_NAME}`} replace /> },
    { path: '/auth/discord', element: <AuthDiscordRedirectHandlerPage /> },

    {
      element: <CompactLayout />,
      children: [{ path: '500', element: <Page500 /> }],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" /> },
  ]);
}
