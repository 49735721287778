// eslint-disable-next-line import/no-cycle
import { FirebaseTypes } from 'src/types/Firebase';
import { SupportedNetworks } from 'src/types/Network';
import { GiftoinUtils } from 'src/utils/giftoin';
import { Giftoin } from 'src/types/Giftoin';
import { ReturnObject } from '../types/org-logger/ReturnObject';
import { baseWebServer } from './baseWebServer';

const baseUrl = 'api/giftoins';

type IGiftoin = FirebaseTypes.Giftoin.Interfaces.IGiftoin;

export interface GiftoinGetPublicResponseGiftoin extends Omit<FirebaseTypes.Product.IProduct, "status"> {
  giftoinId: string;
  status: FirebaseTypes.Giftoin.Enums.Status;
}

export type GiftoinGetPublicResponse = {
  giftoin: GiftoinGetPublicResponseGiftoin;
  voucher: {
    status: FirebaseTypes.Voucher.Enums.Status;
  };
}


export interface IGetManyResponse {
  curWallet: {
    pendingGiftoins: Giftoin.IGiftoin[];
    walletDetails: {
      name: string;
      icon: string;
    };
  };
  otherWallets: {
    pendingGiftoins: number;
    walletDetails: {
      name: string;
      icon: string;
    };
  }[];
}


// If the giftoin contains any kind of crypto asset, allow unwrap
/**
 * Whenever a we receive a giftoin from the backend this transformation function is a MUST
 */
// const transformGiftoinResponse = (giftoin: IGiftoin) => {

//   // Set the giftoin status to Claiming if it has any asset in Claiming status
//   const hasAssetInClaiming = GiftoinUtils.isAnyAssetInClaiming(giftoin.items.coupons);
//   const normalizedGiftoin: Giftoin.IGiftoin = {
//     ...giftoin,
//     // Can unwrap only if giftoin is accepted, contains coins, and at least one coin is available to unwrap
//     unwrapable: giftoin.status === "Accepted" && giftoin.assets.ERC20.length > 0 && !!giftoin.assets.ERC20.find(asset => asset.status === "Pending"),
//     // Can send only if the giftoin is accepted all of the assets wasn't used already
//     sendable: giftoin.status === "Accepted" && giftoin.assets.ERC1155.every(asset => asset.status === "Pending"),
//   }
//   if (hasAssetInClaiming) {
//     normalizedGiftoin.status = "Claiming";
//   }

//   return normalizedGiftoin;

// }


export const giftoinApi = baseWebServer.injectEndpoints({
  endpoints: (builder) => ({
    unwrapGiftoin: builder.mutation<ReturnObject, { giftoinId: string, walletAddress: string, tokenId: number }>({
      query: (args: any) => ({
        url: `${baseUrl}/unwrap`,
        method: 'POST',
        body: { ...args },
      }),
      invalidatesTags: ['Giftoin', 'Giftoins']
    }),
    getMany: builder.query<IGetManyResponse, { giftoinId?: string; resellerName: string; }>({
      query: (args) => ({
        url: `${baseUrl}/many`,
        params: { ...args }
      }),
      providesTags: ['Giftoin', 'Giftoins'],
      keepUnusedDataFor: 0,
      transformResponse: (baseQueryReturnValue: ReturnObject,
        meta: unknown,
        arg: unknown,
      ) => {

        const { curWallet } = baseQueryReturnValue.data as IGetManyResponse;
        return baseQueryReturnValue.data as IGetManyResponse
      },
    }),
    getOne: builder.query<Giftoin.IGiftoin | null, { giftoinId: string }>({
      query: (args) => ({
        url: `${baseUrl}/one/${args.giftoinId}`,
        params: {}
      }),
      providesTags: ['Giftoin', 'Giftoins'],
      keepUnusedDataFor: 0,
      transformResponse: (baseQueryReturnValue: ReturnObject,
        meta: unknown,
        arg: unknown,
      ) => {
        if (baseQueryReturnValue.data) {
          return baseQueryReturnValue.data as Giftoin.IGiftoin;
        }
        return null
      },
    }),
    acceptGiftoin: builder.mutation<Boolean, { giftoinId: string }>({
      query: (args) => ({
        url: `${baseUrl}/${args.giftoinId}/accept`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: ['Giftoin', 'Giftoins', 'Inventory'],
      transformResponse: (baseQueryReturnValue: ReturnObject,
        meta: unknown,
        arg: unknown,
      ) => baseQueryReturnValue.data as boolean,
    }),
    reply: builder.mutation<Boolean, { message: string, sendToEmail: string }>({
      query: (args) => ({
        url: `${baseUrl}/reply`,
        method: 'POST',
        body: { ...args },
      }),
      invalidatesTags: ['Giftoin', 'Giftoins'],
      transformResponse: (baseQueryReturnValue: ReturnObject,
        meta: unknown,
        arg: unknown,
      ) => baseQueryReturnValue.data as boolean,
    }),
    getPublic: builder.mutation<GiftoinGetPublicResponse, { message: string, sendToEmail: string }>({
      query: (args) => ({
        url: `${baseUrl}/reply`,
        method: 'POST',
        body: { ...args },
      }),
      invalidatesTags: ['Giftoin', 'Giftoins'],
      transformResponse: (baseQueryReturnValue: ReturnObject,
        meta: unknown,
        arg: unknown,
      ) => baseQueryReturnValue.data as GiftoinGetPublicResponse,
    }),


  }),
})

export const {
  useGetManyQuery,
  useGetOneQuery,
  useAcceptGiftoinMutation,
  useReplyMutation,
} = giftoinApi;

