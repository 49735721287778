import { ISocialIcon } from "./types"



const facebook = (url: string) => ({
    value: 'facebook',
    name: 'FaceBook',
    icon: 'eva:facebook-fill',
    color: '#1877F2',
    path: url,
} as ISocialIcon)
const instagram = (url: string) => ({
    value: 'instagram',
    name: 'Instagram',
    icon: 'ant-design:instagram-filled',
    color: '#E02D69',
    path: url,
} as ISocialIcon)
const linkedin = (url: string) => ({
    value: 'linkedin',
    name: 'Linkedin',
    icon: 'eva:linkedin-fill',
    color: '#007EBB',
    path: url,
} as ISocialIcon)
const twitter = (url: string) => ({
    value: 'twitter',
    name: 'Twitter',
    icon: 'bi:twitter-x',
    color: '#00AAEC',
    path: url,
} as ISocialIcon)

const youtube = (url: string) => ({
    value: 'youtube',
    name: 'Youtube',
    icon: 'ant-design:youtube-filled',
    color: '#ea5a47',
    path: url,
} as ISocialIcon)

const appleMusic = (url: string) => ({
    value: 'appleMusic',
    name: 'Apple Music',
    icon: 'simple-icons:applemusic',
    color: '#FB536C',
    path: url,
} as ISocialIcon)

const spotify = (url: string) => ({
    value: 'spotify',
    name: 'Spotify',
    icon: 'mdi:spotify',
    color: '#1ED760',
    path: url,
} as ISocialIcon)


const tiktok = (url: string) => ({
    value: 'tiktok',
    name: 'Tiktok',
    icon: 'ic:baseline-tiktok',
    color: '#000',
    path: url,
} as ISocialIcon)

const discord = (url: string) => ({
    value: 'discord',
    name: 'Discord',
    icon: 'skill-icons:discord',
    color: '#nnn',
    path: url,
} as ISocialIcon)

export const getSocialSettings = (name: string, url: string) => {

    switch (name) {
        case "facebook":
            return facebook(url);
        case "instagram":
            return instagram(url);
        case "linkedin":
            return linkedin(url);
        case "twitter":
            return twitter(url);
        case "youtube":
            return youtube(url);
        case "appleMusic":
            return appleMusic(url);
        case "spotify":
            return spotify(url);
        case "tiktok":
            return tiktok(url);
        case "discord":
            return discord(url);

        default:
            break;
    }

    return undefined;
}


