/* eslint-disable no-template-curly-in-string */
import { FirebaseTypes } from "src/types/Firebase";


export const _resellerSettings: FirebaseTypes.Reseller.Wallet.ISettings = {
    pages: {
        login: {
            title: "Welcome to Giftoin Wallet", // max of 23 chars
            subtitle: "Powered By Giftoin", // dynamic max of 90
            previousVersions: []
        },
        giftoins: {
            menu: [{
                name: "Make Your Own", // 23 chars
                url: "https://giftoin.com/starter-edition"
            }
            ],
            banners: {
                main: {
                    title: "Welcome!", // 30 chars
                    subtitle: "This is your Giftoin Wallet", // 250 chars
                    image: "https://firebasestorage.googleapis.com/v0/b/gt-org-dev.appspot.com/o/resellers%2Fwallet%2Fwelcome_screen.png?alt=media&token=5db37d27-9bd0-430a-b1ae-ef0abeeee879", // 360x270
                    cta: {
                        text: "Want to know more about the community?", // 38 chars
                        url: "https://giftoin.com"
                    }
                },
                secondary: {
                    items: [
                    ]
                }
            }
        }
    },
    components: {
        footer: {
            text1: "The starting point for your blockchain and gifts interactions, built on the newest tech", // 150 chars
            text2: "© 2023. All rights reserved", // 150 chars
            socialLinks: {
                facebook: "https://minimals.cc/components",
                youtube: "",
                twitter: "",
                instagram: "",
                spotify: "",
                appleMusic: "",
                linkedin: "",
            }
        },
    },
    global: {
        media: {
            logo: "https://firebasestorage.googleapis.com/v0/b/gt-org-dev.appspot.com/o/resellers%2Fwallet%2Fgiftoin_logo_long_dark.svg?alt=media&token=f338df89-b4a2-43a9-a5bf-3c15b6f49337", // 100x100
            favIcon: "",
        },
        palette: {
            lighter: '#C8FAD6',
            light: '#5BE49B',
            main: '#00A76F',
            dark: '#007867',
            darker: '#004B50',
            contrastText: '#FFFFFF',
        },
        seo: {
            title: "Giftoin Wallet" // 15 chars
        }
    },
    name: "",
    resellerId: "",
}