// routes
import { SelectionArea } from 'src/types/Creator';
import { Icons } from 'src/_mock/assets/icons/Icons';
import Iconify from 'src/components/iconify';
import { IWalletView } from 'src/sections/wallet/wallet-preview/types';
import { useAppSelector } from 'src/redux/store';
import { useMemo } from 'react';
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  wrap: icon('ic_wrap_icon_blockchain'),
  assets: icon('ic_asset_icon_blockchain'),
};

// const navConfig = [
//   // GENERAL
//   // ----------------------------------------------------------------------
//   {
//     subheader: '',
//     items: [
//       {
//         title: 'Assets',
//         path: PATH_DASHBOARD.user.root,
//         selectionArea: null,
//         icon: ICONS.assets,
//         children: [
//           {
//             title: 'ERC20', selectionArea: SelectionArea.AssetsERC20
//           },
//           {
//             title: 'ERC721', selectionArea: SelectionArea.AssetsERC721
//           },
//         ],
//       },
//       {
//         title: 'Wrap',
//         path: PATH_DASHBOARD.user.root,
//         selectionArea: null,
//         icon: ICONS.wrap,
//         children: [
//           {
//             title: 'Properties', selectionArea: SelectionArea.WrapProperties
//           },
//           {
//             title: 'Design', selectionArea: SelectionArea.WrapDesign
//           },
//         ],
//       },
//     ],
//   },

//   // MANAGEMENT
//   // ----------------------------------------------------------------------
//   // {
//   //   subheader: '',
//   //   items: [
//   //     {
//   //       title: 'Blockchain',
//   //       path: PATH_DASHBOARD.user.root,
//   //       icon: ICONS.analytics,
//   //       children: [
//   //         { title: 'Chains', selectionArea: SelectionArea.AssetsERC20 },
//   //       ],
//   //     },
//   //   ],
//   // },
//   // {
//   //   subheader: '',
//   //   items: [
//   //     {
//   //       title: 'Gift',
//   //       path: PATH_DASHBOARD.user.root,
//   //       icon: ICONS.analytics,
//   //       children: [
//   //         { title: 'Category', path: PATH_DASHBOARD.user.four },
//   //         { title: 'Expiration date', path: PATH_DASHBOARD.user.four },
//   //         { title: 'Quantity', path: PATH_DASHBOARD.user.four },
//   //       ],
//   //     },
//   //   ],
//   // },
//   // {
//   //   subheader: '',
//   //   items: [
//   //     {
//   //       title: 'Cover',
//   //       path: PATH_DASHBOARD.user.root,
//   //       icon: ICONS.analytics,
//   //       children: [
//   //         { title: 'Template', path: PATH_DASHBOARD.user.four },
//   //         { title: 'Custom Wrap', path: PATH_DASHBOARD.user.four },
//   //       ],
//   //     },
//   //   ],
//   // },
// ];



// const navConfig: {
//   title: string;
//   value: IWalletView;
//   icon: React.ReactElement;
// }[] = [{
//   title: 'Home',
//   value: 'home',
//   icon: <Iconify icon={Icons.DefaultIcons.home} />
// },
// {
//   title: 'Inventory',
//   value: 'cards',
//   icon: <Iconify icon={Icons.DefaultIcons.inventory} />
// },
// {
//   title: 'Collections',
//   value: 'collections',
//   icon: <Iconify icon={Icons.DefaultIcons.cards} />
// },
// {
//   title: 'Coupons',
//   value: 'coupons',
//   icon: <Iconify icon={Icons.DefaultIcons.coupon} />
// },
// {
//   title: 'Rewards',
//   value: 'rewards',
//   icon: <Iconify icon={Icons.DefaultIcons.reward} />
// },
// {
//   title: 'Marketplace',
//   value: 'shop',
//   icon: <Iconify icon={Icons.DefaultIcons.shop} />
// },
//   ]

// export default navConfig;



export default function useNavConfig() {


  const shop = useAppSelector(state => state.resellerSettings.shop)

  const data = useMemo<{
    title: string;
    value: IWalletView;
    icon: React.ReactElement;
  }[]>(() => {

    const navConfig: {
      title: string;
      value: IWalletView;
      icon: React.ReactElement;
    }[] = [{
      title: 'Home',
      value: 'home',
      icon: <Iconify icon={Icons.DefaultIcons.home} />
    },
    {
      title: 'Inventory',
      value: 'cards',
      icon: <Iconify icon={Icons.DefaultIcons.inventory} />
    },
    {
      title: 'Collections',
      value: 'collections',
      icon: <Iconify icon={Icons.DefaultIcons.cards} />
    },

        // {
        //   title: 'Rewards',
        //   value: 'rewards',
        //   icon: <Iconify icon={Icons.DefaultIcons.reward} />
        // },
      ]

    if (shop?.isEnabled) {
      navConfig.push({
        title: 'Marketplace',
        value: 'shop',
        icon: <Iconify icon={Icons.DefaultIcons.shop} />
      },)
    }
    return navConfig;
  }, [shop?.isEnabled])

  return data;
}