// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// theme
import Logo from 'src/components/logo';
import { useSettingsContext } from 'src/components/settings';
import { HEADER, NAV } from 'src/config-global';
import useOffSetTop from 'src/hooks/useOffSetTop';
import useResponsive from 'src/hooks/useResponsive';
import { bgBlur } from 'src/utils/cssStyles';
import { Switch } from '@mui/material';
import { DefaultIcons } from 'src/components/icons/defaults';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import WalletSelect from './WalletSelect';
import { AccountDrawer } from './account-drawer';
import { AccountSettingsDrawer } from './account-settings/account-settings';
import ThemeModeButton from './theme-mode-button';

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();

  const settings = useSettingsContext();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';
  console.log("🚀 ~ Header ~ isNavMini:", isNavMini)

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const isMobile = useResponsive('down', 'sm');

  const renderContent = (
    <>
      {/* {!lgUp && <Logo sx={{ mr: 2.5 }} />} */}
      <Logo sx={{ mr: 2.5 }} />


      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}

        sx={{ bgcolor: 'background.default', }}
      >
        {/* comment out by chai */}
        {/* <LanguagePopover /> */}

        {/* <NotificationsPopover /> */}

        {/* comment out by chai */}
        {/* <ContactsPopover /> */}

        {/* <SettingsButton /> */}
        {/* {!isMobile && <CoinsBalances />} */}

        {!isMobile && <ThemeModeButton />}
        <Stack direction='row' spacing={1} display='flex' justifyContent='center' alignItems='center'>

          <WalletSelect />
          {/* <AccountPopover /> */}
          <AccountDrawer data={[{
            label: "Account Settings",
            action: () => openAccountSettings.onTrue(),
            icon:
              // <m.div
              //   animate={{ rotate: 360 }}
              //   transition={{ duration: 8, ease: 'linear', repeat: Infinity }}
              // >

              <Iconify
                icon={DefaultIcons.settings}
                height={24}
                width={24}

              />
            // </m.div>
          }]} />
        </Stack>

      </Stack >
    </>
  );
  const openAccountSettings = useBoolean();

  return <>
    <AppBar
      sx={{
        height: HEADER.H_DESKTOP,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: '#F4F6F8',
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          // width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            // bgcolor: '#F4F6F8',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
          bgcolor: 'background.default'
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>

    <AccountSettingsDrawer open={openAccountSettings.value} onClose={openAccountSettings.onFalse} />

  </>
}




function LightModeButton() {
  const isDarkMode = useBoolean();
  return (
    <Switch
      checked={isDarkMode.value}
      onChange={isDarkMode.onToggle}
      icon={<NightsStayIcon />}
      checkedIcon={<WbSunnyIcon />}
      sx={{
        padding: 7,
        '& .MuiSwitch-switchBase': {
          padding: 1,
          '&.Mui-checked': {
            transform: 'translateX(36px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              background: 'linear-gradient(to right, #000046, #1CB5E0)', // Stars background
              opacity: 1,
            },
          },
        },
        '& .MuiSwitch-thumb': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 32,
          height: 32,
          backgroundColor: isDarkMode ? '#FFD700' : '#87CEEB', // Sun or cloud color
        },
        '& .MuiSwitch-track': {
          borderRadius: 20 / 2,
          opacity: 1,
          backgroundColor: isDarkMode ? '#3E3E3E' : '#87CEEB', // Dark or light background
          position: 'relative',
          '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          },
          '&:before': {
            backgroundImage: isDarkMode ? 'url(/path/to/stars.png)' : 'none', // Stars when dark mode
          },
          '&:after': {
            backgroundImage: !isDarkMode ? 'url(/path/to/clouds.png)' : 'none', // Clouds when light mode
          },
        },
      }}
    />
  );


}

