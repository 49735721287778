import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link, BoxProps } from '@mui/material';
import { useAppSelector } from 'src/redux/store';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const { logo: logoUrl } = useAppSelector(state => state.resellerSettings.global.media);

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={sx}
        {...other}
      >
        <Box component="img" src={logoUrl} alt="logo" sx={{ width: { xs: 60, md: 60 }, height: 'auto', ...sx }} />
      </Box>
    );
    // const logo_2 = <Box component="img" src="/logo/logo_full_2.svg" sx={{ width: 40, height: 40, ...sx }} />;

    if (disabledLink) {
      return <>{logo}</>;
    }

    return <RouterLink to="../">{logo}</RouterLink>;
  }
);

export default Logo;