// @mui
import { Link, List, ListItemText, Stack } from '@mui/material';
// locales
import { useAppDispatch } from 'src/redux/hooks';
import { IWalletView } from 'src/sections/wallet/wallet-preview/types';
import { updateWalletView } from 'src/redux/slices/userSlice';
import { useAppSelector } from 'src/redux/store';
import { useLocales } from '../../../locales';
//
import { NavSectionProps } from '../types';
import { StyledIcon, StyledItem, StyledSubheader } from './styles';
import NavList from './NavList';

// ----------------------------------------------------------------------

interface IProps extends Omit<NavSectionProps, "data"> {
  data: {
    title: string;
    value: IWalletView;
    icon: React.ReactElement;
  }[];
}



export default function NavSectionVerticalRedux({ data, sx, ...other }: IProps) {
  const { translate } = useLocales();

  const dispatch = useAppDispatch();

  const currentView = useAppSelector(state => state.user.walletView);

  return (
    <Stack sx={sx} {...other}>
      {data.map((item) => (<NavItem icon={item.icon} active={currentView === item.value} title={item.title} value={item.value} />))}
    </Stack>
  );
}





interface INavItemProps {
  icon: React.ReactElement;
  active: boolean;
  title: string;
  value: IWalletView;
}


function NavItem({ icon, active, title, value }: INavItemProps) {
  const { translate } = useLocales();
  const dispatch = useAppDispatch();
  const handleClick = () => {
    dispatch(updateWalletView(value));
  }

  return <Link underline="none" onClick={handleClick}>

    <StyledItem active={active} depth={1}  >
      {icon && <StyledIcon>{icon}</StyledIcon>}

      <ListItemText
        primary={translate(title) as any}
        // secondary={
        //   caption && (
        //     <Tooltip title={translate(caption) as any} placement="top-start">
        //       <span>{translate(caption) as any}</span>
        //     </Tooltip>
        //   )
        // }
        primaryTypographyProps={{
          noWrap: true,
          component: 'span',
          variant: active ? 'subtitle2' : 'body2',
        }}
        secondaryTypographyProps={{
          noWrap: true,
          variant: 'caption',
        }}
      />


    </StyledItem>
  </Link>

}
