import { useState, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from 'src/redux/store';
// components
import { PATH_AUTH } from 'src/routes/paths';
import LoadingScreen from '../components/loading-screen';

//

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticatedWeb2, isInitialized, isConnecting } = useAppSelector(state => state.auth);

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (isConnecting) {
    return <LoadingScreen />;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticatedWeb2) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    // eslint-disable-next-line no-restricted-globals
    return <Navigate to={PATH_AUTH.login} />;

  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <> {children} </>;
}
