import type { IconButtonProps } from '@mui/material/IconButton';

import { useState, useCallback } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { alpha, useTheme } from '@mui/material/styles';

// import { paths } from 'src/routes/paths';
// import { useRouter, usePathname } from 'src/routes/hooks';


import { AnimateAvatar } from 'src/components/animate';


import { Label } from '@mui/icons-material';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useAppSelector } from 'src/redux/store';
import { Link } from '@mui/material';
import { DefaultIcons } from 'src/components/icons/defaults';
import { AccountButton } from './account-button';
import { SignOutButton } from './sign-out-button';
import ThemeModeButton from './theme-mode-button';

// ----------------------------------------------------------------------

export type AccountDrawerProps = IconButtonProps & {
  data?: {
    label: string;
    // href: string;
    action: () => void;
    icon?: React.ReactNode;
    info?: React.ReactNode;
  }[];
};



export function AccountDrawer({ data = [], sx, ...other }: AccountDrawerProps) {
  const theme = useTheme();

  // const router = useRouter();

  // const pathname = usePathname();

  // const { user } = useMockedUser();

  const user = useAppSelector(state => state.auth.user)

  const [open, setOpen] = useState(false);


  const handleOpenDrawer = useCallback(() => {
    setOpen(true);
  }, []);

  const handleCloseDrawer = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClickItem = useCallback(
    (path: string) => {
      handleCloseDrawer();
      // router.push(path);
    },
    [handleCloseDrawer]
  );

  const { isPopUpBlocked } = useAppSelector(state => state.user);

  const menuItemsExternal = useAppSelector(state => state.resellerSettings.pages.giftoins.menu)

  const renderAvatar = (
    <AnimateAvatar
      width={96}
      slotProps={{
        avatar: { src: user?.photoURL, alt: user?.displayName },
        overlay: {
          border: 2,
          spacing: 3,
          color: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0)} 25%, ${theme.palette.primary.main} 100%)`,
        },
      }}
    >
      {user?.displayName?.charAt(0).toUpperCase()}
    </AnimateAvatar>
  );


  return (
    <>
      <Box onClick={handleOpenDrawer} sx={{ cursor: "pointer" }}>
        <Stack direction='row'>

          <AccountButton
            open={open}
            photoURL={user?.photoURL}
            displayName={user?.displayName}
            sx={sx}
            {...other}
          />
          <IconButton >
            <Iconify width={28} p={0} icon={DefaultIcons.menu} />
          </IconButton>

        </Stack>
      </Box>

      <Drawer
        open={open}
        onClose={handleCloseDrawer}
        anchor="right"
        slotProps={{ backdrop: { invisible: true } }}
        PaperProps={{ sx: { width: 320, } }}
      >
        <Stack display='flex' justifyContent='space-between' height='100%'>
          <Box>
            <IconButton
              onClick={handleCloseDrawer}
              sx={{ top: 12, left: 12, zIndex: 9, position: 'absolute' }}
            >
              <Iconify icon="mingcute:close-line" />
            </IconButton>

            <Scrollbar>
              <Stack alignItems="center" sx={{ pt: 8 }}>
                {renderAvatar}

                {user?.displayName && <Typography variant="subtitle1" noWrap sx={{ mt: 2 }}>
                  {user.displayName}
                </Typography>}

                {user?.email && <Typography variant="body2" sx={{ color: 'text.secondary', mt: 0.5 }} noWrap>
                  {user.email}
                </Typography>}
              </Stack>

              <Stack
                sx={{
                  py: 3,
                  px: 2.5,
                  borderTop: `dashed 1px ${theme.palette.divider}`,
                  borderBottom: `dashed 1px ${theme.palette.divider}`,
                }}
              >
                {menuItemsExternal.map((item, index) => (
                  <Link target={isPopUpBlocked ? "_top" : "_blank"} key={index} underline="none" href={item.url} color="inherit" sx={{ color: 'inherit', textDecoration: 'none' }}>

                    <MenuItem
                      key={item.name}
                      sx={{
                        py: 1,
                        color: 'text.secondary',
                        '& svg': { width: 24, height: 24 },
                        '&:hover': { color: 'text.primary' },
                      }}
                    >
                      <Iconify icon={DefaultIcons.externalLink} />

                      <Box component="span" sx={{ ml: 2 }}>
                        {item.name}
                      </Box>

                      {/* {option.info && (
                    <Label color="error" sx={{ ml: 1 }}>
                      {option.info}
                    </Label>
                  )} */}
                    </MenuItem>
                  </Link>
                ))}
              </Stack>
              <Stack
                sx={{
                  py: 3,
                  px: 2.5,
                }}
              >
                {data.map((option) => {
                  const rootLabel = "".includes('/dashboard') ? 'Home' : 'Dashboard';

                  const rootHref = "".includes('/dashboard') ? '/' : '/wallet/home';

                  return (
                    <MenuItem
                      key={option.label}
                      onClick={() => {
                        handleClickItem(rootHref);
                        option.action();
                      }}
                      sx={{
                        py: 1,
                        color: 'text.secondary',
                        '& svg': { width: 24, height: 24 },
                        '&:hover': { color: 'text.primary' },
                      }}
                    >
                      {option.icon}

                      <Box component="span" sx={{ ml: 2 }}>
                        {option.label === 'Home' ? rootLabel : option.label}
                      </Box>

                      {option.info && (
                        <Label color="error" sx={{ ml: 1 }}>
                          {option.info}
                        </Label>
                      )}
                    </MenuItem>
                  );
                })}

              </Stack>

              {/* <Box sx={{ px: 2.5, py: 3 }}>
            <UpgradeBlock />
          </Box> */}
            </Scrollbar>
          </Box>

          <Box sx={{ p: 2.5, }}>
            <SignOutButton onClose={handleCloseDrawer} />
          </Box>
        </Stack>

      </Drawer>

    </>
  );
}








