import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getSession, jwtDecode } from "src/auth/utils";
import { FIREBASE_API } from "src/config-global";

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);


export const prepareHeaders = async (headers: Headers, { getState }: { getState: any }) => {



    const token = await getSession();

    // const token = await AUTH.currentUser?.getIdToken();
    // const { exp } = jwtDecode(token ?? ""); // ~3 days by minimals server

    // const currentTime = Date.now();

    // // Test token expires after 10s
    // // const timeLeft = currentTime + 10000 - currentTime; // ~10s
    // const refreshToken = (exp * 1000 - currentTime) < 10; // ~ 10s before idToken supposed to be expired

    if (token) {
        // const token = await AUTH.currentUser?.getIdToken();

        headers.set('x-giftoin-id-token', token)
    } else {
        console.log("no token available")
    }
    return headers
}
