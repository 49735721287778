import { FirebaseTypes } from 'src/types/Firebase';
import { LoyaltyTypes } from 'src/types/loyalty/loyalty';
import { ObjectValues } from 'src/types/utils';
import { ILogicalOperator } from 'src/types/logical-operator';
import { ProgramTypes } from 'src/types/loyalty/program';
import { SupportedNetworks } from 'src/types/Network';
import { ITimestamp } from 'src/types/timestamp';
import { GlobalConfig } from 'src/config-global';
import { ReturnObject, ReturnObjectGeneric } from '../types/org-logger/ReturnObject';
import { baseWebServer } from './baseWebServer';
import { ServerTypes } from './types';




const normalizeProgramReward = (r: LoyaltyApiTypes.Program.IReward): ProgramTypes.IReward => {
    const { rewardId, logicalOperator, details } = r;
    if (details) {

        const { id, image, timeLimit, title, type, quantity, maxRedemptions, description, inStock, viewType, redeem, createdTimestamp, environment, success, cost } = details;
        return {
            rewardId,
            logicalOperator,
            details: {
                id,
                title,
                description,
                image,
                maxRedemptions,
                quantity,
                inStock,
                timeLimit,
                viewType,
                type,
                createdTimestamp: new Date(createdTimestamp._seconds * 1000),
                environment: environment === "mainnet" ? "production" : "sandbox",
                redeem,
                success,
                cost
            }
        };
    }
    return { rewardId, logicalOperator };
}


export const programApi = baseWebServer.injectEndpoints({
    endpoints: (builder) => ({
        getPrograms: builder.query<LoyaltyTypes.Program.IProgram[], { resellerName: string }>({
            query: (args) => ({
                url: `/api/reseller/${args.resellerName}/loyalty/programs`,
                // params: { populate: '*' },
            }),
            providesTags: ['Programs'],
            transformResponse: (baseQueryReturnValue: ReturnObjectGeneric<LoyaltyApiTypes.Program.GetAll.IResponse>,
                meta: unknown,
                arg: unknown,
            ) => {
                if (baseQueryReturnValue.data) {
                    // Prepared this section for possible response transform in the future between the server's response schemas to the client's schemas
                    const transformedData: LoyaltyTypes.Program.IProgram[] = baseQueryReturnValue.data.map((program) => {
                        let { image } = program;
                        if (!program.image || program.image === "/assets/images/programs/program_default_image.png") {
                            image = GlobalConfig.LOYALTY_PROGRAM_DEFAULT_IMAGE;
                        }

                        let globalStatus: LoyaltyTypes.Program.IProgram["globalStatus"] = "Coming Soon";

                        if (Date.now() > (program.duration?.start?.date ?? 0)) {
                            globalStatus = "In Progress";
                        } else {
                            globalStatus = "Coming Soon"
                        }

                        if (program.duration?.end?.date && (Date.now() > (program.duration.end.date))) {
                            globalStatus = "Ended";
                        }


                        if (program.status === "completed") {
                            return {
                                ...program,
                                createdDate: new Date(program.createdTimestamp._seconds * 1000),
                                environment: program.environment === "mainnet" ? "production" : "sandbox",
                                rewards: program.rewards.map(reward => normalizeProgramReward(reward)),
                                status: "completed",
                                completedDate: new Date(program.completedTimestamp._seconds * 1000),
                                image,
                                globalStatus,

                            }
                        }
                        return {
                            ...program,
                            createdDate: new Date(program.createdTimestamp._seconds * 1000),
                            environment: program.environment === "mainnet" ? "production" : "sandbox",
                            rewards: program.rewards.map(reward => normalizeProgramReward(reward)),
                            image,
                            globalStatus,

                        }

                    });
                    return transformedData;

                }
                return [];
            }
        }),
        claimReward: builder.mutation<LoyaltyTypes.Reward.Instance.IInstance | undefined, { resellerName: string; rewardId: string; programId: string }>({
            query: (args) => ({
                url: `/api/reseller/${args.resellerName}/loyalty/programs/${args.programId}/claim/${args.rewardId}`,
                method: 'POST',
            }),
            invalidatesTags: ['Programs', 'Giftoins', 'Inventory'],
            transformResponse: (baseQueryReturnValue: ReturnObjectGeneric<LoyaltyApiTypes.Program.RewardClaim.IResponse>,
                meta: unknown,
                arg: unknown,
            ) => {
                if (baseQueryReturnValue.data) {
                    return baseQueryReturnValue.data
                }
                return undefined;
            }

        }),



    }),
})

export const {
    useGetProgramsQuery,
} = programApi;



export namespace LoyaltyApiTypes {




    export namespace Reward {
        const INTERVALS = ["daily", "weekly", "monthly", "lifetime"]

        export const intervals = {
            "daily": "daily",
            "weekly": "weekly",
            "monthly": "monthly",
            "lifetime": "lifetime",
        } as const;

        export type IInterval = ObjectValues<typeof intervals>;

        export const viewType = {
            "text": "text",
            "QR": "QR",
        } as const;

        export type IViewType = ObjectValues<typeof viewType>;


        export const rewardTypes = {
            "code": "code",
            "points": "points",
            "token": "token",
        } as const;

        export type IType = ObjectValues<typeof rewardTypes>;



        export namespace TimeLimit {
            export const types = {
                "unlimited": "unlimited",
                "fixed_duration": "fixed_duration"
            };

            export type ITimeLimitType = ObjectValues<typeof types>;

            interface ITimeLimitBase {
                type: ITimeLimitType;
            }

            interface ITimeLimitUnlimited extends ITimeLimitBase {
                type: 'unlimited';
            }

            interface ITimeLimitFixedDuration {
                type: 'fixed_duration';
                startDate: Date;
                endDate: Date;
            }

            export type ITimeLimit = ITimeLimitFixedDuration | ITimeLimitUnlimited;
        }

        export namespace Code {
            export const status = {
                "Available": "Available",
                "Reserved": "Reserved",
                "Claiming": "Claiming",
                "Claimed": "Claimed",
                "Used": "Used",

            } as const;

            export type IStatus = ObjectValues<typeof status>;

            export interface ICode {
                value: string;
                status: IStatus;
                ownerUid: string;
                createdTimestamp: ITimestamp;
            }
        }

        export namespace Instance {
            export const status = {
                "Available": "Available",
                "Reserved": "Reserved",
                "Claiming": "Claiming",
                "Claimed": "Claimed",
                "Used": "Used",

            } as const;

            export type IStatus = ObjectValues<typeof status>;

            export interface IInstance {
                id: string;
                value: string;
                status: IStatus;
                userId?: string;
                programId?: string;
                claimedTimestamp?: ITimestamp;
                createdTimestamp: ITimestamp;
            }
        }




        export type IReward = {
            id: string;
            title: string;
            description: string;
            image: string;
            maxRedemptions: {
                amount: number;
                interval: IInterval;
            };
            quantity: number; // How many of this reward created
            inStock: number;
            timeLimit: TimeLimit.ITimeLimit;
            viewType: IViewType;
            type: IType;
            createdTimestamp: ITimestamp;
            environment: ServerTypes.IEnvironment;
            // rewardId: string; // The actual reward saving the code in the db.
            // };
            redeem: {

            };
            success: {
                message: string;
            };
            cost: boolean;
            codes?: string[];
        }
        export namespace GetAll {
            export namespace Request {

            }
            export type IResponse = IReward[];
        }



        export namespace CreateOne {
            export namespace Request {
                export interface IBody extends Omit<IReward, "id" | "createdTimestamp"> {
                    codes: string[];
                }

            }

            export type IResponse = { rewardId: string; codesId: string[] };

        }
    }


    export namespace Program {




        export namespace Requirement {
            export const types = {
                // SURVEY_COMPLETION: "SURVEY_COMPLETION",
                // REFERRAL: "REFERRAL",
                // SOCIAL_MEDIA_SHARE: "SOCIAL_MEDIA_SHARE",
                // PRODUCT_REVIEW: "PRODUCT_REVIEW",
                OWNING_TOKEN: "owning_token",
            } as const;
            export type IType = ObjectValues<typeof types>;

            export interface IRequirementBase {
                type: IType;
                logicalOperator: ILogicalOperator;
            }

            export const tokenTypes = {
                "Card": "Card",
            } as const;

            export type ITokenType = ObjectValues<typeof tokenTypes>;

            export interface IRequirementOwningToken extends IRequirementBase {
                type: "owning_token";
                token: {
                    network: SupportedNetworks;
                    tokenId: string;
                    contractAddress: string;
                    type: ITokenType;
                    amount: number;
                    name: string;
                    description: string;
                    image: string;
                    id: string;
                    collectionId: string;
                }
            }

            // export interface IRequirementSurveyCompletion {
            // }
            export type IRequirement = IRequirementOwningToken;


        }


        // export namespace Reward {
        //     export const rewardTypes = {
        //         DISCOUNT_COUPON: "DISCOUNT_COUPON",
        //         EXCLUSIVE_ACCESS: "EXCLUSIVE_ACCESS",
        //         CUSTOM_AVATAR: "CUSTOM_AVATAR",
        //         EARLY_PRODUCT_RELEASE: "EARLY_PRODUCT_RELEASE",
        //     } as const;
        // }
        export interface IReward {
            rewardId: string; // The id of the reward in the rewards collection (other collection under the reseller)
            logicalOperator: ILogicalOperator;
            details?: Reward.IReward;
        };
        const status = {
            "completed": "completed",
            "in_progress": "in_progress",
        } as const;

        export type IStatus = ObjectValues<typeof status>;



        export interface IProgramBase {
            status: IStatus;
            name: string;
            description: string;
            image?: string;
            requirements: Requirement.IRequirementOwningToken[];
            rewards: IReward[];
            id: string;
            environment: ServerTypes.IEnvironment;
            createdTimestamp: ITimestamp;
            duration?: {
                start?: {
                    date?: number | null;
                    showBefore: boolean;
                };
                end?: {
                    date?: number | null;
                    showAfter: boolean;
                };
            };
        }
        export interface IProgramInProgress extends IProgramBase {
            status: "in_progress";
        }

        export interface IProgramCompleted extends IProgramBase {
            status: "completed"
            completedTimestamp: ITimestamp;
            claimedRewards: { rewardId: string; instance: Reward.Instance.IInstance }[];
        }


        export type IProgram = IProgramInProgress | IProgramCompleted;



        export namespace GetAll {
            export namespace Request {

            }
            export type IResponse = IProgram[];
        }

        export namespace GetOne {
            export namespace Request {

            }
            export type IResponse = IProgram;
        }




        export namespace CreateOne {
            export namespace Request {
                export interface IBody extends Omit<IProgram, "id" | "createdTimestamp"> {
                }

            }

            export type IResponse = { id: string };

        }

        export namespace RewardClaim {
            export namespace Request {
                export interface IParams {
                    programId: string;
                    rewardId: string;
                    resellerId: string;
                }


            }

            export type IResponse = Reward.Instance.IInstance;
        }






    }


    export namespace Collection {

        export interface IBaseMetadata extends Partial<{
            image: string;
            name: string;
            description: string;
            external_url: string;
            animation_url: string;
            attributes: { trait_type: string; value: string }[];
            background_color: string;
            youtube_url: string;
            file_type: string;
        }> {

        }

        export interface IERC1155Metadata extends IBaseMetadata {

        }
        export interface ICollectionMetadata extends Partial<Pick<IBaseMetadata, "image" | "name" | "description">> {
            external_link?: string;
        }


        export const tokenTypes = {
            "ERC20": "ERC20",
            "ERC721": "ERC721",
            "ERC1155": "ERC1155",
        } as const;

        export type IType = ObjectValues<typeof tokenTypes>;

        interface IAssetBase {
            // type: IType;
            network: SupportedNetworks;
            contractAddress: string;
        }


        export interface IAssetERC20 extends IAssetBase {
            network: SupportedNetworks;
            contractAddress: string;
            decimals: number;
        }

        export interface IAssetERC721 extends IAssetBase {
            metadata: IERC1155Metadata;
            tokenId: string
        }
        export interface IAssetERC1155 extends IAssetBase {
            metadata: IERC1155Metadata;
            tokenId: string
        }






        export interface ICollectionBase {
            metadata: ICollectionMetadata;
            assetsType: IType;
            // assets: IAssetERC20[] | IAssetERC721[] | IAssetERC1155[];
            id: string;
            createdTimestamp: ITimestamp;
            network: SupportedNetworks;
            contractAddress: string;
        }

        export interface ICollectionERC20 extends ICollectionBase {
            assetsType: "ERC20";
            assets: IAssetERC20[];
        }
        export interface ICollectionERC721 extends ICollectionBase {
            assetsType: "ERC721";
            assets: IAssetERC721[];
        }

        export interface ICollectionERC1155 extends ICollectionBase {
            assetsType: "ERC1155";
            assets: IAssetERC1155[];
        }

        export type ICollection = ICollectionERC20 | ICollectionERC721 | ICollectionERC1155;


        export namespace GetAll {
            export namespace Request {
                export interface IQuery {
                    populate?: '*';
                }

            }
            export type IResponse = ICollection[];
        }


        export namespace ImportCollection {
            export namespace Request {
                export interface IBody extends Omit<ICollection, "id" | "createdTimestamp"> {
                }

            }

            export type IResponse = { id: string };

        }



    }




}


