

export namespace DefaultIcons {
    export const subscriptionLock = "material-symbols:lock";
    export const insufficientCoins = "tabler:coin-off";
    export const filter = "ic:baseline-filter-alt";
    export const filterOff = "ic:baseline-filter-alt-off"
    export const gamification = 'ic:baseline-videogame-asset';
    export const airdrop = 'fluent-mdl2:parachute-solid';
    export const coin = 'ph:coin-fill';
    export const coins = 'ph:coins-fill';
    export const coupon = 'mdi:coupon';
    export const gift = 'tabler:gift-filled';
    export const giftcard = 'f7:giftcard-fill';
    export const collectCard = 'fluent:collections-24-filled';
    export const cards = "mdi:cards-outline";
    export const settings = "eva:settings-2-outline";
    export const reward = "fluent:reward-12-regular";
    export const productsStock = "fluent-mdl2:product-variant";
    export const loyalty = "material-symbols:loyalty-outline";
    export const home = "heroicons:home-solid";
    export const users = "mdi:users";
    export const campaign = "ic:round-campaign";
    export const clock = 'mdi:clock';
    export const google = 'grommet-icons:google';
    export const phone = 'teenyicons:phone-solid';
    export const email = 'eva:email-fill';
    export const discord = 'skill-icons:discord';
    export const steam = 'mdi:steam';
    export const roblox = 'simple-icons:roblox';
    export const externalLink = 'eva:external-link-fill';
    export const twitter = 'bi:twitter-x';
    export const name = 'wpf:name';
    export const userGeneralDetails = 'ph:user-list-bold';
    export const connection = 'mdi:connection';
    export const id = 'tabler:id';
    export const menu = 'tabler:menu-3';
    export const copy = 'mingcute:copy-fill';
}