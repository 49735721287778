import { ReturnObject } from 'src/types/org-logger/ReturnObject';
import { baseWebServer } from './baseWebServer';

// const baseUrl = "api/authentication/";
const baseUrl = "/auth/";

export const userAuth = baseWebServer.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation<boolean, { emailAddress: string; resellerName: string }>({
            query: (args) => ({
                url: `${baseUrl}login`,
                method: 'POST',
                body: { ...args },
            }),
            invalidatesTags: ['User'],
            transformResponse: (baseQueryReturnValue: ReturnObject,
                meta: unknown,
                arg: unknown,
            ) => baseQueryReturnValue.data as boolean,
        }),
        findUser: builder.mutation<{ userType: string, }, { emailAddress: string }>({
            query: (args) => ({
                url: `${baseUrl}find-user`,
                method: 'POST',
                body: { ...args },
            }),
            invalidatesTags: ['User'],
            transformResponse: (baseQueryReturnValue: ReturnObject,
                meta: unknown,
                arg: unknown,
            ) => baseQueryReturnValue.data as { userType: string, emailAddress: string },
        }),
    }),
})
