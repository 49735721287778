/* eslint-disable import/no-cycle */
import {
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit'
import { INotification } from 'src/layouts/studio/header/notifications-popover/types';



const notificationsAdapter = createEntityAdapter<INotification>({
    // Assume IDs are stored in a field other than `book.id`
    selectId: (notification) => notification.id,
    // Keep the "all IDs" array sorted based on book titles
    sortComparer: (a, b) => {
        if (a.createdAt.getDate() < b.createdAt.getDate()) {
            return 1
        }
        if (a.createdAt.getDate() === b.createdAt.getDate()) {
            return 0;
        }
        return -1;
    },
})

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: notificationsAdapter.getInitialState(),
    reducers: {
        // Can pass adapter functions directly as case reducers.  Because we're passing this
        // as a value, `createSlice` will auto-generate the `bookAdded` action type / creator
        // bookAdded: giftoinsAdapter.addOne,
        setOne: notificationsAdapter.setOne,
        setAll: notificationsAdapter.setAll,
        removeAll: notificationsAdapter.removeAll,
        removeOne: notificationsAdapter.removeOne,
        addOne: notificationsAdapter.addOne,
        updateMany: notificationsAdapter.updateMany,
    },
});



