import { FirebaseTypes } from 'src/types/Firebase';
import { LoyaltyTypes } from 'src/types/loyalty/loyalty';
import { ObjectValues } from 'src/types/utils';
import { ILogicalOperator } from 'src/types/logical-operator';
import { ProgramTypes } from 'src/types/loyalty/program';
import { SupportedNetworks } from 'src/types/Network';
import { ITimestamp } from 'src/types/timestamp';
import { GlobalConfig } from 'src/config-global';
import { ReturnObject, ReturnObjectGeneric } from '../types/org-logger/ReturnObject';
import { baseWebServer } from './baseWebServer';
import { ServerTypes } from './types';





export const connectionApi = baseWebServer.injectEndpoints({
    endpoints: (builder) => ({
        getConnections: builder.query<ConnectionApiTypes.Connection.IConnection[], {}>({
            query: (args) => ({
                url: `/api/connections`,
                // params: { populate: '*' },
            }),
            providesTags: ['Connections'],
            transformResponse: (baseQueryReturnValue: ReturnObjectGeneric<ConnectionApiTypes.Connection.IConnection[]>,
                meta: unknown,
                arg: unknown,
            ) => {

                if (baseQueryReturnValue.data) {
                    // Prepared this section for possible response transform in the future between the server's response schemas to the client's schemas
                    return baseQueryReturnValue.data;

                }
                return [];
            }
        }),




    }),
})

export const {
    useGetConnectionsQuery,
} = connectionApi;



export namespace ConnectionApiTypes {


    export namespace Connection {
        export const ConnectionTypes = {
            Discord: "discord",
            Roblox: "roblox",
            Twitter: "twitter",
            Steam: "steam",
        } as const;
        export type IConnectionType = typeof ConnectionTypes[keyof typeof ConnectionTypes];

        export interface IConnection {
            type: IConnectionType;
            id: string;
            name: string;
            createdTimestamp: number;
            lastUpdateTimestamp: number;
        }


    }





}


