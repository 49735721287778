import { initializeApp } from "firebase/app";
import { getAuth, getRedirectResult, onAuthStateChanged } from "firebase/auth";
import { isValidToken } from "src/auth/utils";
import { FIREBASE_API } from "src/config-global";
import { initialize, updateIsConnecting } from "src/redux/slices/authSlice";
import { RootState, store, useAppDispatch } from "src/redux/store";
import localStorageAvailable from "src/utils/localStorageAvailable";
import { useEffect } from "react";
import { updateIsPopUpBlocked } from "src/redux/slices/userSlice";
import useLogin from "./useLogin";
// import { DraftDocument } from "src/types/Draft";
// import { SupportedNetworks } from "src/types/Network";
// import useCreateDraftLocal from "../creator/useCreateDraftLocal";

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);


/**
 * Check only on initialization if the sessions is part of a redirect auth
 */
export default function useCheckIfAuthRedirect() {

    const dispatch = useAppDispatch();

    const { login } = useLogin();
    useEffect(() => {
        const checkRedirectResult = async () => {
            dispatch(updateIsConnecting(true));

            const response = await getRedirectResult(AUTH);

            if (response) {
                const resellerName = store.getState().resellerSettings.name;
                if (!resellerName) return; // Prevent attempt to login when redirected to 500 or 400 page
                const res = await login(response.user);
                if (!res) {
                    // Failed to login with server - only set the auth to initialized = true
                    dispatch(initialize({}))
                } else {
                    dispatch(updateIsPopUpBlocked(true))
                }

            }
            dispatch(updateIsConnecting(false));

        }
        checkRedirectResult()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


}