import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { WEB_SERVER_URL } from 'src/config-global';
import { prepareHeaders } from './utils';

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseWebServer = createApi({
    reducerPath: 'webServer',
    baseQuery: fetchBaseQuery({
        baseUrl: `${WEB_SERVER_URL}`, prepareHeaders
    }),
    tagTypes: ['User', 'Giftoin', 'Quests', 'Assets_Points', 'Shop', 'Connection', 'Connections', 'Programs', 'Inventory', 'Rewards', 'Giftoins', 'Network', 'Coin', 'Product', 'Abi', 'Contract', 'Voucher', 'Settings'],
    endpoints: () => ({}),
})