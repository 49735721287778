// eslint-disable-next-line import/no-cycle
import { Inventory } from 'src/types/Inventory';
import { ReturnObject } from '../types/org-logger/ReturnObject';
import { baseWebServer } from './baseWebServer';

const baseUrl = 'api/accounts';





export namespace Send {
    export interface IRequestBody {
        // fromEmailAddress: string;
        toEmailAddress?: string;
        toPhoneNumber?: string;
        toThirdPartyId?: {
            type: "discord";
            id: string;
        };

        message: { title: string; body: string; };
        senderName: string;
        sendEmail: boolean; // Send email to recipient about this new giftoin
        items: {
            cards: { id: string; quantity: number; }[];
            points: { id: string; quantity: number; scope: "RESELLER" | "GLOBAL" }[];
            coupons: { id: string; }[];
        }
    }
    export type IResponse = { transactionId: string; status: "Completed" | "Failed" };
}


export type IGetInventoryResponse = { cards: Inventory.ICard[]; points: Inventory.IPoint[]; coupons: Inventory.Coupon.ICoupon[]; rewards: Inventory.Reward.IReward[] };


export const inventoryApi = baseWebServer.injectEndpoints({
    endpoints: (builder) => ({
        getInventory: builder.query<IGetInventoryResponse, { resellerName: string; }>({
            query: (args) => ({
                url: `${baseUrl}/${args.resellerName}/inventory`,
                params: {}
            }),
            providesTags: ['Inventory'],
            keepUnusedDataFor: 0,
            transformResponse: (baseQueryReturnValue: ReturnObject,
                meta: unknown,
                arg: unknown,
            ) => baseQueryReturnValue.data as IGetInventoryResponse,
        }),

        send: builder.mutation<Send.IResponse, {
            params: { resellerName: string }, body: Send.IRequestBody
        }>({
            query: (args) => ({
                url: `${baseUrl}/${args.params.resellerName}/inventory/send`,
                method: 'POST',
                body: args.body,
            }),
            invalidatesTags: ['Giftoin', 'Giftoins', 'Inventory'],
            transformResponse: (baseQueryReturnValue: ReturnObject,
                meta: unknown,
                arg: unknown,
            ) => baseQueryReturnValue.data as Send.IResponse,
        }),

        claimCoupon: builder.mutation<{ code: string }, { couponId: string; resellerName: string }>({
            query: (args) => ({
                url: `${baseUrl}/${args.resellerName}/inventory/coupons/${args.couponId}/claim`,
                method: 'POST',
                body: {},
            }),
            invalidatesTags: ['Giftoin', 'Giftoins', 'Inventory'],
            transformResponse: (baseQueryReturnValue: ReturnObject,
                meta: unknown,
                arg: unknown,
            ) => baseQueryReturnValue.data as { code: string },
        }),

    }),
})

export const {
    useGetInventoryQuery,
} = inventoryApi;

