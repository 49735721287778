// routes
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { FIREBASE_API } from 'src/config-global';
import { fDateTime } from 'src/utils/formatTime';
import { PATH_AUTH } from '../routes/paths';
// utils
import axios from '../utils/axios';

// ----------------------------------------------------------------------

export function jwtDecode(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------
const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const GOOGLE_PROVIDER = new GoogleAuthProvider();

export const tokenExpired = (exp: number) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime - 10000; // ~ 10s before idToken supposed to be expired

  clearTimeout(expiredTimer);
  // console.log("set up timer", fDateTime(new Date(exp * 1000)))

  // its only one time updating the token, after that it will check if the idToken is expired upon refresh!
  expiredTimer = setTimeout(async () => {
    console.log("Token expired");
    // Update access token - refreshing the user's id token
    const token = await AUTH.currentUser?.getIdToken(true);
    if (token) {
      console.log("Token updated");
      setSession(token);
    } else {
      // If id token can't be fetched - redirect to login page
      alert("Session timed out - redirecting to login page")
      // console.log("session timed out and token is missing");
      window.location.href = PATH_AUTH.login;
    }
  }, timeLeft);
};



// ----------------------------------------------------------------------

export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);

    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    // ! Firebase Auth always refreshing the id token for us
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server

    tokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');

    delete axios.defaults.headers.common.Authorization;
  }
};

export const getSession = async () => {
  let token = localStorage.getItem('accessToken');
  if (token) {
    const { exp } = jwtDecode(token); // ~3 days by minimals server
    const currentTime = Date.now();
    const timeLeft = exp * 1000 - currentTime - 10000; // ~ 10s before idToken supposed to be expired

    if (timeLeft < 0) {
      // Current token in local storage is expired, need to refetch it 
      token = await AUTH.currentUser?.getIdToken(true) ?? "";
    }
  } else {
    token = await AUTH.currentUser?.getIdToken(true) ?? "";
  }

  return token;
}





// 1. In the background check the user is in session, and when expire just replace it.