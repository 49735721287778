/* eslint-disable consistent-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectionArea } from "src/types/Creator";
import { ItemsPerRowEnum } from "src/types/User";
import { GiftoinFunctionsEnum } from 'src/types/GiftoinFunctions';
import { Giftoin } from 'src/types/Giftoin';
import { IWalletView } from "src/sections/wallet/wallet-preview/types";



interface UserState {
  selectionArea: SelectionArea;
  giftoinsPerRow: ItemsPerRowEnum;
  /**
   * The selected giftoin by the user
   */
  giftoinId: string | undefined;
  giftoinFunctionView: GiftoinFunctionsEnum;
  showSettingsButton: boolean;
  walletView: IWalletView;
  isPopUpBlocked: boolean;
};

const initialState: UserState = {
  selectionArea: SelectionArea.Assets,
  giftoinsPerRow: ItemsPerRowEnum.Row5,
  giftoinId: undefined,
  giftoinFunctionView: GiftoinFunctionsEnum.Default,
  showSettingsButton: true,
  walletView: "home",
  isPopUpBlocked: false,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateNumberOfItemsPerRow: (state, action: PayloadAction<{ items: ItemsPerRowEnum }>) => {
      state.giftoinsPerRow = action.payload.items;
    },
    removeGiftoinInfo: (state) => {
      state.giftoinId = undefined;
    },
    showGiftoinInfo: (state, action: PayloadAction<{ giftoinId: string | undefined }>) => {
      state.giftoinId = action.payload.giftoinId;
    },
    updateSelectedGiftoin: (state, action: PayloadAction<{ giftoinId: string | undefined }>) => {
      state.giftoinId = action.payload.giftoinId;
    },
    updateShowSettingsButton: (state, action: PayloadAction<boolean>) => {
      state.showSettingsButton = action.payload;
    },
    updateWalletView: (state, action: PayloadAction<UserState["walletView"]>) => {
      window.scrollTo(0, 0); // When switching views need to also reset the scroller to top
      state.walletView = action.payload;
    },
    updateIsPopUpBlocked: (state, action: PayloadAction<boolean>) => {
      state.isPopUpBlocked = action.payload;
    },

  }
});


export const {
  updateNumberOfItemsPerRow,
  removeGiftoinInfo,
  showGiftoinInfo,
  updateShowSettingsButton,
  updateWalletView,
  updateSelectedGiftoin,
  updateIsPopUpBlocked,
} = slice.actions;

export default slice.reducer;



