/* eslint-disable consistent-return */
import { createEntityAdapter, createSelector, createSlice, PayloadAction, Update } from "@reduxjs/toolkit";
import { giftoinApi, IGetManyResponse } from "src/api/giftoinApi";
import { Giftoin } from 'src/types/Giftoin';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../store';


interface IWallet {
    pendingGiftoins: number;
    walletDetails: { name: string; icon: string; }
}


const walletAdapter = createEntityAdapter<IWallet>({
    selectId: (wallet) => wallet.walletDetails.name,
    // // Keep the "all IDs" array sorted based on book titles
    // sortComparer: (a, b) => {
    //     if (a.giftedDate < b.giftedDate) {
    //         return 1
    //     }
    //     if (a.giftedDate === b.giftedDate) {
    //         return 0;
    //     }
    //     return -1;
    // },
})
const slice = createSlice({
    name: 'wallet',
    initialState: walletAdapter.getInitialState(),
    reducers: {
        updateWallet: walletAdapter.setOne,
        resetWallet: walletAdapter.removeAll,
    },
    extraReducers(builder) {
        builder.addMatcher(
            giftoinApi.endpoints.getMany.matchFulfilled,
            // Update the local giftoins cache
            (state, action) => {
                const { curWallet } = action.payload;
                const wallets = action.payload.otherWallets.concat([{
                    walletDetails: curWallet.walletDetails,
                    pendingGiftoins: curWallet.pendingGiftoins.length
                }]);
                // Check if its first time updating cache
                const isConnectedFirstTime = Object.keys(state.entities).length === 0;
                if (isConnectedFirstTime) {
                    // First time - setting the giftoins data - overwriting the current empty [] giftoins entities
                    walletAdapter.setMany(state, wallets);

                } else {
                    // Not first time - updating the current giftoins data saved in cache 
                    const changes: Update<IWallet>[] = wallets.map(wallet => {
                        const entityState: Update<IWallet> = {
                            id: wallet.walletDetails.name,
                            changes: wallet,
                        };
                        return entityState
                    })
                    walletAdapter.updateMany(state, changes);
                }

            }
        )
    },

});


export const {
    updateWallet,
    resetWallet,
} = slice.actions;


/* Can create a set of memoized selectors based on the location of this entity state
   By using the RootState from store we are using the giftoinsSlice created here
   
   This is crucial in order to use 
   */
export const walletSelectors = walletAdapter.getSelectors<RootState>(
    (state) => state.wallet,
)

export const selectEntities = createSelector((state: RootState) => walletSelectors.selectEntities(state), wallet => wallet);

export const selectAll = createSelector((state: RootState) => walletSelectors.selectAll(state), wallets => wallets);


export default slice.reducer;



