import { Stack, Typography, IconButton, Drawer, Tabs, Tab, Container } from "@mui/material";





import Iconify from "src/components/iconify";
import { useTabs } from "src/hooks/use-tabs";
import { CustomTabs } from "src/components/custom-tabs";
import Label from "src/components/label";
import { DefaultIcons } from "src/components/icons/defaults";
import { AccountConnectionsView, AccountGeneralView } from "./views";



// ----------------------------------------------------------------------

const TABS = [
    { value: 'general', label: 'General', icon: <Iconify icon={DefaultIcons.userGeneralDetails} width={24} /> },
    { value: 'connections', label: 'Connections', icon: <Iconify icon={DefaultIcons.connection} width={24} /> },
    // {
    //     value: 'notifications',
    //     label: 'Notifications',
    //     icon: <Iconify icon="solar:bell-bing-bold" width={24} />,
    // },
    // { value: 'social', label: 'Social links', icon: <Iconify icon="solar:share-bold" width={24} /> },
    // { value: 'security', label: 'Security', icon: <Iconify icon="ic:round-vpn-key" width={24} /> },
];

// ----------------------------------------------------------------------

interface IProps {
    open: boolean;
    onClose: () => void;
}

export function AccountSettingsDrawer({ open, onClose }: IProps) {
    // const drawer = useBoolean();

    const tabs = useTabs('general');



    const renderHead = (
        <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
                Account Settings
            </Typography>


            <IconButton onClick={onClose} sx={{ display: { xs: 'inline-flex', sm: 'none' } }}>
                <Iconify icon="mingcute:close-line" />
            </IconButton>

        </Stack>
    );




    return (
        <Drawer
            open={open}
            onClose={onClose}
            anchor="right"
            slotProps={{ backdrop: { invisible: true } }}
            PaperProps={{ sx: { width: 1, maxWidth: 420 } }}
        >
            {renderHead}
            <Container>

                {/* {renderTabs} */}
                <Tabs value={tabs.value} onChange={tabs.onChange} sx={{ mb: { xs: 3, md: 5 } }}>
                    {TABS.map((tab) => (
                        <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
                    ))}
                </Tabs>

                {tabs.value === 'general' && <AccountGeneralView />}
                {tabs.value === 'connections' && <AccountConnectionsView />}
            </Container>

        </Drawer>
    );
}


















