/* eslint-disable consistent-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FirebaseTypes } from "src/types/Firebase";
import { VoucherValidateResponseGiftoin } from "src/api/voucherApi";


interface IVoucher {
    key: string;
    status: FirebaseTypes.Voucher.Enums.Status;
}

interface RedeemState {
    voucher: IVoucher | null;
    giftoin: VoucherValidateResponseGiftoin | null;
};

const initialState: RedeemState = {
    voucher: null,
    giftoin: null,
};

const slice = createSlice({
    name: 'redeem',
    initialState,
    reducers: {
        updateVoucher: (state, action: PayloadAction<Partial<IVoucher>>) => {
            const key = action.payload.key ?? state.voucher?.key;
            const status = action.payload.status ?? state.voucher?.status;

            if (key && status) {
                state.voucher = { key, status };
            }
        },

        updateGiftoin: (state, action: PayloadAction<{ giftoin: VoucherValidateResponseGiftoin }>) => {
            state.giftoin = action.payload.giftoin;
        },
        resetRedeem: (state) => initialState,
    }
});


export const { updateGiftoin, updateVoucher, resetRedeem } = slice.actions;

export default slice.reducer;



