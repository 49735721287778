import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// ----------------------------------------------------------------------

// export const RedeemPage = Loadable(lazy(() => import('../pages/public/RedeemPage')));

export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));
export const AuthDiscordRedirectHandlerPage = Loadable(lazy(() => import('src/pages/auth/AuthDiscordRedirectHandlerPage')));
export const AuthCallbackPage = Loadable(lazy(() => import('src/pages/auth/AuthCallbackPage')));

export const WalletPage = Loadable(lazy(() => import('../pages/studio/WalletPage')));

// export const PageOne = Loadable(lazy(() => import('../pages/PageOne')));
// export const PageTwo = Loadable(lazy(() => import('../pages/PageTwo')));
// export const PageThree = Loadable(lazy(() => import('../pages/PageThree')));
// export const PageFour = Loadable(lazy(() => import('../pages/PageFour')));
// export const PageFive = Loadable(lazy(() => import('../pages/PageFive')));
// export const PageSix = Loadable(lazy(() => import('../pages/PageSix')));

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
