import { initializeApp } from 'firebase/app';
import { getAuth, getRedirectResult } from 'firebase/auth';
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { FIREBASE_API } from 'src/config-global';
import useCheckIfAuthRedirect from 'src/hooks/auth/useCheckIfAuthRedirect';
import useCheckIfUserIsConnected from 'src/hooks/auth/useCheckIfUserIsConnected';
import useLogin from 'src/hooks/auth/useLogin';
import { updateIsConnecting, initialize } from 'src/redux/slices/authSlice';
import { useAppDispatch, store, useAppSelector } from 'src/redux/store';

type AuthLayerProps = {
    children: React.ReactNode;
};
const firebaseApp = initializeApp(FIREBASE_API);

export const AUTH = getAuth(firebaseApp);

/**
 * Initialize the auth slice 
 * 
 * Detect if the user is already logged in - update the redux slice accordingly:
 * * Detect wallet
 * * Detect firebase auth user
 * 
 * Initiate wallet listeners
 * * When user changes network
 * * When user disconnect changes account on metamask
 * 
 */
export function AuthLayer({ children }: AuthLayerProps) {
    const { resellerId } = useParams();

    const { checkIfUserIsConnected } = useCheckIfUserIsConnected();
    useCheckIfAuthRedirect();

    checkIfUserIsConnected();
    const dispatch = useAppDispatch();

    const { login } = useLogin();

    useEffect(() => {
        const checkRedirectResult = async () => {
            dispatch(updateIsConnecting(true));

            const response = await getRedirectResult(AUTH);

            if (response) {
                const resellerName = store.getState().resellerSettings.name;
                if (!resellerName) return; // Prevent attempt to login when redirected to 500 or 400 page
                const res = await login(response.user);
                if (!res) {
                    // Failed to login with server - only set the auth to initialized = true
                    dispatch(initialize({}))
                }
            }
            dispatch(updateIsConnecting(false));

        }
        checkRedirectResult()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <>{children}</>;

}

export default AuthLayer

