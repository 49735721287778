/* eslint-disable consistent-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { _resellerSettings } from "src/_mock/_resellerSettings";
import { FirebaseTypes } from "src/types/Firebase";


type ISettings = FirebaseTypes.Reseller.Wallet.ISettings;

const initialState: ISettings = _resellerSettings;

const slice = createSlice({
    name: 'resellerSettings',
    initialState,
    reducers: {
        updateResellerSettings: (state, action: PayloadAction<ISettings>) => action.payload,
        updateResellerName: (state, action: PayloadAction<{ name: string }>) => { state.name = action.payload.name },
        updateResellerId: (state, action: PayloadAction<{ id: string }>) => { state.resellerId = action.payload.id },
    }
});


export const {
    updateResellerSettings,
    updateResellerName,
    updateResellerId,
} = slice.actions;





export default slice.reducer;



