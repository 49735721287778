// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_WALLET = '/wallet';
const ROOTS_AUTH = '../auth';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '../auth',
  register: '../auth',
  logout: '../wallet',
  resetPassword: path(ROOTS_AUTH, '/reset-password'),

};
export const PATH_PUBLIC = {
  redeem: 'redeem',
}

export const PATH_HOME = '../';

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  drafts: path(ROOTS_DASHBOARD, '/drafts'),
  two: path(ROOTS_DASHBOARD, '/two'),
  three: path(ROOTS_DASHBOARD, '/three'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    four: path(ROOTS_DASHBOARD, '/user/four'),
    five: path(ROOTS_DASHBOARD, '/user/five'),
    six: path(ROOTS_DASHBOARD, '/user/six'),
  },
};
export const PATH_WALLET = {
  root: ROOTS_WALLET,
  home: path(ROOTS_WALLET, '/home'),
};



export const PATHS = {
  wallet: {
    collections: {
      details: path(ROOTS_WALLET, '/collection')
    }
  }
}