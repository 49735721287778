import { FirebaseTypes } from 'src/types/Firebase';
import { ReturnObject } from '../types/org-logger/ReturnObject';
import { baseWebServer } from './baseWebServer';

const baseUrl = 'api/settings';





export const settingsApi = baseWebServer.injectEndpoints({
    endpoints: (builder) => ({
        getSettings: builder.query<{ settings: Omit<FirebaseTypes.Reseller.Wallet.ISettings, "name">; resellerId: string; } | null, { resellerName: string }>({
            query: (args) => ({
                url: `${baseUrl}`,
                params: { ...args }
            }),
            providesTags: ['Settings'],
            keepUnusedDataFor: 0,
            transformResponse: (baseQueryReturnValue: ReturnObject,
                meta: unknown,
                arg: unknown,
            ) => baseQueryReturnValue.data as { settings: Omit<FirebaseTypes.Reseller.Wallet.ISettings, "name">; resellerId: string; } | null,
        }),

    }),
})

export const {
    useGetSettingsQuery,
} = settingsApi;
