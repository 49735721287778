import { Box, FormControl, Grid, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import Label from "src/components/label/Label";
import { selectAllGiftoins } from "src/redux/slices/giftoinsSlice";
import { notificationsSlice } from "src/redux/slices/notificationsSlice";
import { updateResellerName } from "src/redux/slices/resellerSettingsSlice";
import { updateWalletView } from "src/redux/slices/userSlice";
import { selectAll } from "src/redux/slices/wallet";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { Giftoin } from 'src/types/Giftoin';


type IGiftoin = Giftoin.IGiftoin;

const calculateAmountOfGiftedGiftoins = (giftoins: IGiftoin[]) => {
    const count = giftoins.reduce((accumulator, giftoin) => {
        if (giftoin.status === "Gifted") {
            return accumulator + 1;
        }
        return accumulator;
    }, 0);
    return count;
}

export default function WalletSelect() {
    const resellerName = useAppSelector(state => state.resellerSettings.name);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    // const [selectedWallet, setSelectedWallet] = useState(resellerName ?? "");
    const currentWalletGiftoins = useAppSelector(selectAllGiftoins);

    // Need to put the current reseller name first and use the up to date giftoins from giftoinSlice
    const wallets = useAppSelector(selectAll).sort(wallet => {
        if (wallet.walletDetails.name === resellerName) return -1;
        if (wallet.walletDetails.name === resellerName) return 1;
        return 0;
    }).map(wallet => {
        if (wallet.walletDetails.name === resellerName) {
            return {
                ...wallet,
                giftoins: currentWalletGiftoins,
            }
        }
        return wallet;
    })


    const handleChange = (event: SelectChangeEvent) => {
        const redirectToResellerName = event.target.value as string;
        dispatch(updateResellerName({ name: redirectToResellerName }));

        // Reset the notifications of the previous wallet
        dispatch(notificationsSlice.actions.removeAll());
        dispatch(updateWalletView("home"));
        // const currentWallet = walletSelectors.selectById(store.getState(), resellerName);
        // if (currentWallet) {
        //     console.log("🚀 ~ file: WalletSelect.tsx:53 ~ handleChange ~ currentWallet:", currentWallet)
        //     dispatch(setAllGiftoins(currentWallet.giftoins));
        // }
        // Redirect the page to the selected reseller 
        navigate(`/a/${redirectToResellerName}`);
    };

    if (wallets.length < 2) return <></>;

    return <FormControl fullWidth size="small">


        <Select
            placeholder="wallet"
            value={resellerName}
            onChange={handleChange}
            sx={{ maxWidth: '220px', minWidth: '180px' }}
        >


            {wallets.map((wallet, index) =>
                <MenuItem sx={{ pr: 4, ml: -0.3 }} value={wallet.walletDetails.name} key={index}>
                    <Grid gap={1} container height='100%' display='flex' alignItems='center' justifyContent='flex-start'>
                        <Grid item xs={2} height='100%'>
                            <Box component="img" src={wallet.walletDetails.icon} alt={`icon_${wallet.walletDetails.name}`} width='16px' height='16px' />
                        </Grid>
                        <Grid item xs height='100%' >
                            <Typography textTransform='capitalize' fontSize='12px' variant="body2">
                                {wallet.walletDetails.name}
                            </Typography>
                        </Grid>
                        {wallet.pendingGiftoins > 0 &&
                            <Grid item xs={2} minHeight='30px' display='flex' alignItems='center' justifyContent='center' >
                                <AmountOfGiftedLabel amount={wallet.pendingGiftoins} />
                            </Grid>
                        }
                    </Grid>
                </MenuItem>)
            }
        </Select>
    </FormControl>
}






function AmountOfGiftedLabel({ amount }: { amount: number }) {

    if (amount === 0) return <></>;

    // return <Badge badgeContent={count} color="primary" />
    return <Label variant="soft" color="primary" >
        {amount}
    </Label>

}



