import { createSlice, Dispatch } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { EmailAuthProvider, getAuth, GoogleAuthProvider } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { FIREBASE_API } from 'src/config-global';
import { setSession } from 'src/auth/utils';
// eslint-disable-next-line import/no-cycle
import { Network } from 'src/types/Network';
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import { resetGiftoins } from './giftoinsSlice';

interface AuthState {
    isAuthenticatedWeb2: boolean;
    isAuthenticatedWeb3: boolean;
    isInitialized: boolean;
    isConnecting: boolean;
    user: null | Record<string, any>;
    userWeb3: { walletAddress: string | null, isDisconnected: boolean, network: Network | null };
}

const initialState: AuthState = {
    isAuthenticatedWeb2: false,
    isAuthenticatedWeb3: false,
    isInitialized: false,
    user: null,
    userWeb3: {
        walletAddress: null,
        isDisconnected: false,
        network: null,
    },
    isConnecting: false
}

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        initialize(state, action: PayloadAction<{
            isAuthenticatedWeb2?: boolean;
            isAuthenticatedWeb3?: boolean;
            user?: {} | null;
        }>) {
            state.isInitialized = true;
            state.isAuthenticatedWeb2 = action.payload.isAuthenticatedWeb2 ?? false;
            state.isAuthenticatedWeb3 = action.payload.isAuthenticatedWeb3 ?? false;
            state.user = action.payload.user ?? null;
        },
        web2Login(state, action: PayloadAction<{ user: {} }>) {
            const { user } = action.payload;
            state.isAuthenticatedWeb2 = true;
            state.user = user;
            state.isInitialized = true;
            state.isConnecting = false;
        },
        web2Logout(state, action) {
            state.isAuthenticatedWeb2 = false;
            state.isInitialized = true;
            state.user = null;
        },
        web3Connected(state, action: PayloadAction<{ walletAddress: string, network: Network }>) {
            const { walletAddress, network } = action.payload;
            state.isAuthenticatedWeb3 = true;

            if (state.userWeb3) {
                state.userWeb3.network = network;
                state.userWeb3.walletAddress = walletAddress;
            } else {
                state.userWeb3 = {
                    network,
                    walletAddress,
                    isDisconnected: false,
                }

            }
        },
        updateIsConnecting(state, action: PayloadAction<boolean>) {
            state.isConnecting = action.payload;
        },
        /**
         * User decided to disconnect the wallet
         * Because we can't actually disconnect the wallet because of metamask limitations -> just indicate the user the wallet is disconnected
         * @param state 
         * @param action 
         */
        web3Disconnected(state, action) {
            state.isAuthenticatedWeb3 = false;
            state.userWeb3.isDisconnected = true;
            state.userWeb3.network = null;
        },
        web3ChangedNetwork(state, action: PayloadAction<{ network: Network }>) {
            const { network } = action.payload;
            if (state.userWeb3) {
                state.userWeb3.network = network;
            } else {
                alert("Error updating network in auth slice")
            }

        },
        // logoutUser: (state, action: PayloadAction<void>) => initialState // Resets all values to initial state 

    },
})

// Actions
export const { web3ChangedNetwork, updateIsConnecting, web3Connected, web3Disconnected, web2Login, web2Logout, initialize } = slice.actions


// ----------------------------------------------------------------------
// Firebase Authentication

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const GOOGLE_PROVIDER = new GoogleAuthProvider();
const EMAIL_PROVIDER = new EmailAuthProvider();

// ----------------------------------------------------------------------





// External slice functions - need to use dispatch(function())

/**
 * Check if user is already connected by:
 * * Access Token: Check if there is a saved and valid access token in local storage -> attempt to use it for session
 * * Firebase User: Check if we have access to firebase/auth local user -> attempt to login with server
 */
// export function checkIfUserIsConnected() {
//     const storageAvailable = localStorageAvailable();
//     return async (dispatch: Dispatch) => {
//         try {
//             /**
//              * If access token found we wait until the login process is finished before setting the isInitialize to true
//              * 
//              * Show loading screen until finished authenticate user
//              */
//             let accessTokenFound = false;
//             const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
//             if (accessToken && isValidToken(accessToken)) {
//                 await onAuthStateChanged(AUTH, async (firebaseUser) => {
//                     const { isAuthenticatedWeb2 } = (store.getState() as RootState).auth;
//                     if (!isAuthenticatedWeb2) {
//                         if (firebaseUser) {
//                             const res = await login(firebaseUser);
//                             if (!res) {
//                                 // Failed to login with server - only set the auth to initialized = true
//                                 dispatch(initialize({}))
//                             }
//                         }
//                     }


//                 });
//                 accessTokenFound = true;
//             }
//             if (!accessTokenFound) {

//                 dispatch(slice.actions.initialize({}));

//             }


//         } catch (error) {
//             dispatch(slice.actions.initialize({}));
//             console.log(error);
//         }

//     };
// }


export function loginWithEmailAndPassword(email: string, password: string) {
    return async (dispatch: Dispatch) => {
        // const response = await axios.post('/api/account/login', {
        //     email,
        //     password,
        // });
        // const { accessToken, user } = response.data;

        // setSession(accessToken);
        alert("need to implement login with email and password")
    }
}


// export function registerWithEmailAndPassword(email: string, password: string, walletAddress: string) {
//     return async (dispatch: Dispatch) => {
//         try {

//             // Register in google auth
//             const userCredential = await createUserWithEmailAndPassword(AUTH, email, password);
//             const { user } = userCredential;

//             // Register in our database using backend api call
//             // !Implement in new server studio service
//             const createCreatorRes = await userAuth.endpoints.register.initiate({
//                 walletAddress,
//                 name: ''
//             })





//             dispatch(slice.actions.web2Login({ user, walletAddress }))
//             setSession(await user.getIdToken());

//         } catch (error) {
//             const errorCode = error.code;
//             const errorMessage = error.message;
//             alert(errorMessage);
//         }
//     }

// }


export function logout() {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.web2Logout({}));
        // Reset creator slice data
        setSession(null);
        // storage.removeItem('persist:root')
        // dispatch(logoutUser());
        dispatch(resetGiftoins());
        AUTH.signOut();
    }
}


// Reducer
export default slice.reducer


// Selectors


