/* eslint-disable react/button-has-type */
// i18n
import './locales/i18n';

// scroll bar
import 'simplebar/src/simplebar.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------
// redux
import { PersistGate } from 'redux-persist/integration/react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { pdfjs } from 'react-pdf';
import ReactGA4 from 'react-ga4';
import { createWeb3Modal, defaultWagmiConfig, useWeb3Modal } from '@web3modal/wagmi/react'
import { WagmiConfig, useAccount } from 'wagmi'
import { polygon, mainnet, bsc, bscTestnet, Chain } from 'wagmi/chains'
import { defineChain } from 'viem';
import { store, persistor } from './redux/store';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import SnackbarProvider from './components/snackbar';
import ScrollToTop from './components/scroll-to-top';
import { MotionLazyContainer } from './components/animate';
import { ThemeSettings, SettingsProvider } from './components/settings';
// eslint-disable-next-line import/no-named-as-default
import AuthLayer from './auth/AuthLayer';
import { GlobalConfig, REACT_APP_GA_MEASUREMENT_ID } from './config-global';
import AuthModernCompactLayout from './layouts/login/modern-compact';
import MaintenancePage from './pages/maintenance';


// 1. Get projectId
const projectId = GlobalConfig.WALLET_CONNECT_PROJECT_ID;

// 2. Create wagmiConfig
const metadata = {
  name: 'Giftoin Wallet',
  description: 'Giftoin Wallet',
  // url: 'https://web3modal.com',
  // icons: ['https://avatars.githubusercontent.com/u/37784886']
}

// export const polygonAmoy = defineChain({
//   id: 80002,
//   name: 'Amoy',
//   nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
//   rpcUrls: {
//     default: { http: ['https://rpc-amoy.polygon.technology'] },
//     public: {
//       http: ['https://rpc-amoy.polygon.technology'],
//       webSocket: undefined
//     }
//   },
//   blockExplorers: {
//     default: { name: 'OKLink', url: 'https://www.oklink.com/amoy' },
//   },
//   network: 'Amoy'
// })

// const chains = [mainnet, polygon, polygonAmoy, bsc, bscTestnet]
// const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })

// // 3. Create modal
// createWeb3Modal({ wagmiConfig, projectId, chains, themeVariables: { "--w3m-z-index": 199999 } })

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'

// initialize google analytics 4
ReactGA4.initialize(REACT_APP_GA_MEASUREMENT_ID);


// // PDF Setup
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();

export default function App() {

  const { MAINTENANCE_MODE } = GlobalConfig;

  return (
    <HelmetProvider>
      {/* <WagmiConfig config={wagmiConfig}> */}
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/* <AuthProvider> */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>

            <SettingsProvider>
              <BrowserRouter>
                <ScrollToTop />
                <MotionLazyContainer>
                  <ThemeProvider>
                    <ThemeSettings>
                      <ThemeLocalization>
                        <SnackbarProvider>
                          {MAINTENANCE_MODE ?
                            <AuthModernCompactLayout>
                              <MaintenancePage />
                            </AuthModernCompactLayout>
                            :
                            <AuthLayer>
                              <Router />
                            </AuthLayer>
                          }

                        </SnackbarProvider>
                      </ThemeLocalization>
                    </ThemeSettings>
                  </ThemeProvider>
                </MotionLazyContainer>
              </BrowserRouter>
            </SettingsProvider>
          </LocalizationProvider>
          {/* </AuthProvider> */}
        </PersistGate>
      </ReduxProvider>
      {/* </WagmiConfig> */}
    </HelmetProvider>
  );
}
