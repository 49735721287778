/* eslint-disable consistent-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { View } from "src/sections/auth/types";



interface LoginState {
    emailAddress: string;
    curView: View;
    inProgress: boolean; // Is the user currently in login process with backend
};

const initialState: LoginState = {
    emailAddress: "",
    curView: View.Main,
    inProgress: false,
};

const slice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        updateEmailAddress: (state, action: PayloadAction<{ emailAddress: string }>) => {
            state.emailAddress = action.payload.emailAddress;
        },
        updateCurView: (state, action: PayloadAction<{ view: View }>) => {
            state.curView = action.payload.view;
        },
        updateInProgress: (state, action: PayloadAction<{ inProgress: boolean }>) => {
            state.inProgress = action.payload.inProgress;
        },

    }
});


export const {
    updateCurView: updateLoginCurView,
    updateEmailAddress: updateLoginEmailAddress,
    updateInProgress: updateLoginInProgress,
} = slice.actions;





export default slice.reducer;



