/* eslint-disable consistent-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GiftoinFunctionsEnum } from 'src/types/GiftoinFunctions';



interface ViewState {
  giftoinFunctionView: GiftoinFunctionsEnum;
  walletAddress: string;
};

const initialState: ViewState = {
  giftoinFunctionView: GiftoinFunctionsEnum.Default,
  walletAddress: '',
};

const slice = createSlice({
  name: 'view',
  initialState,
  reducers: {
    updateGiftoinFunctionView: (state, action: PayloadAction<{ view: GiftoinFunctionsEnum }>) => {
      state.giftoinFunctionView = action.payload.view;
    },
    returnToDefaultInfoView: (state) => {
      state.giftoinFunctionView = GiftoinFunctionsEnum.Default;
      state.walletAddress = '';
    },
    updateWalletAddress: (state, action: PayloadAction<{ walletAddress: string }>) => {
      state.walletAddress = action.payload.walletAddress;
    },
  }
});


export const {
  updateGiftoinFunctionView,
  returnToDefaultInfoView,
  updateWalletAddress,
} = slice.actions;

export default slice.reducer;



