import { useTheme } from "@mui/material";
import { useGetProgramsQuery } from "src/api/programApi";
import LoadingScreen from "src/components/loading-screen";
import { useBoolean } from "src/hooks/use-boolean";
import { useAppSelector } from "src/redux/store";


export default function ProgramCollectionDetailsPage() {

    const resellerName = useAppSelector(state => state.resellerSettings.name);
    const { data: programs, isLoading, isError } = useGetProgramsQuery({ resellerName });




    const theme = useTheme();


    if (!programs) {
        return <LoadingScreen />
    }

    if (programs.length === 0) {
        return "No programs"
    }

    // const isInspect = useBoolean(true);
    return <></>
}