import { initializeApp } from "firebase/app";
import { getAuth, getRedirectResult, onAuthStateChanged } from "firebase/auth";
import { isValidToken } from "src/auth/utils";
import { FIREBASE_API } from "src/config-global";
import { initialize, updateIsConnecting, web2Login } from "src/redux/slices/authSlice";
import { RootState, store, useAppDispatch } from "src/redux/store";
import localStorageAvailable from "src/utils/localStorageAvailable";
import { useEffect } from "react";
import { wait } from "src/utils/axios";
import useLogin from "./useLogin";
// import { DraftDocument } from "src/types/Draft";
// import { SupportedNetworks } from "src/types/Network";
// import useCreateDraftLocal from "../creator/useCreateDraftLocal";

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);



export default function useCheckIfUserIsConnected() {

    const dispatch = useAppDispatch();


    // useEffect(() => {
    //     const checkRedirectResult = async () => {
    //         dispatch(updateIsConnecting(true));

    //         const response = await getRedirectResult(AUTH);
    //         console.log("🚀 ~ checkRedirectResult ~ response:", response)

    //         if (response) {
    //             const resellerName = store.getState().resellerSettings.name;
    //             if (!resellerName) return; // Prevent attempt to login when redirected to 500 or 400 page
    //             const res = await login(response.user);
    //             if (!res) {
    //                 // Failed to login with server - only set the auth to initialized = true
    //                 dispatch(initialize({}))
    //             }
    //         }
    //     }
    //     checkRedirectResult()
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])


    const { login } = useLogin();
    /**
 * If access token found we wait until the login process is finished before setting the isInitialize to true
 * 
 * Show loading screen until finished authenticate user
 */
    const checkIfUserIsConnected = async () => {
        try {
            const storageAvailable = localStorageAvailable();

            let accessTokenFound = false;
            const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
            if (accessToken) {
                await onAuthStateChanged(AUTH, async (firebaseUser) => {

                    let resellerName: string | undefined;
                    while (!resellerName) {
                        resellerName = store.getState().resellerSettings.name;
                        // eslint-disable-next-line no-await-in-loop
                        await wait(1000);
                    }

                    // TODO: Wait until reseller name is exist
                    const { isAuthenticatedWeb2 } = (store.getState() as RootState).auth;

                    if (!isAuthenticatedWeb2) {
                        if (firebaseUser) {
                            // const resellerName = store.getState().resellerSettings.name;
                            // if (!resellerName) {
                            //     alert("need to login but does not have the reseller name saved - stuck");
                            //     return;
                            // }; // Prevent attempt to login when redirected to 500 or 400 page
                            const res = await login(firebaseUser);
                            if (!res) {
                                // Failed to login with server - only set the auth to initialized = true
                                dispatch(initialize({}))
                            }
                        } else {
                            // Firebase user not defined, meaning need to initialize
                            dispatch(initialize({}))
                        }
                    } else if (firebaseUser) {
                        dispatch(web2Login({ user: firebaseUser }));
                    } else {
                        dispatch(initialize({}))
                    }



                });
                accessTokenFound = true;
            }
            if (!accessTokenFound) {

                dispatch(initialize({}));

            }


        } catch (error) {
            dispatch(initialize({}));
            console.log(error);
        }
    }


    return { checkIfUserIsConnected }

}