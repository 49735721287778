import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { Box, Container, Stack } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import { useSettingsContext } from '../../components/settings';
//
import Main from './Main';
import Header from './header';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';
import NavHorizontal from './nav/NavHorizontal';
import Footer from './footer/Footer';
import FixedBottomNavigation from './nav/bottom-navigation';

// ----------------------------------------------------------------------

interface IProps {

}


export default function StudioLayout() {
  const { themeLayout } = useSettingsContext();

  const isDesktop = useResponsive('up', 'lg');
  const isMobile = useResponsive('down', 'lg');

  const [open, setOpen] = useState(false);

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;


  // ! This is only for testing purposes, to delete when desktop version is available!
  // return <Container component="main">
  //   <Stack
  //     sx={{
  //       py: 12,
  //       m: 'auto',
  //       maxWidth: { xs: 400, lg: '100%' },
  //       minHeight: '100vh',
  //       textAlign: 'center',
  //       justifyContent: 'center',
  //     }}
  //   >
  //     <Header onOpenNav={handleOpen} />

  //     <Outlet />
  //     <Footer />
  //     <FixedBottomNavigation />

  //   </Stack>
  // </Container>



  // if (isNavHorizontal) {
  //   return (
  //     <>
  //       <Header onOpenNav={handleOpen} />

  //       {/* TODO: uncomment when we need navigation */}
  //       {/* {isDesktop ? <NavHorizontal /> : renderNavVertical} */}

  //       <Main>
  //         <Outlet />
  //       </Main>
  //     </>
  //   );
  // }

  if (isNavMini) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
            bgcolor: 'background.default'

          }}
        >
          {/* TODO: uncomment when we need navigation */}
          {isDesktop ? <NavMini /> : renderNavVertical}

          <Main>
            <Outlet />
          </Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={handleOpen} />

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { xs: '100%', lg: 1 },
          bgcolor: 'background.default'
        }}
      >
        {/* TODO: uncomment when we need navigation */}
        {!isMobile && renderNavVertical}

        <Main >
          <Outlet />
        </Main>
      </Box>
      <Footer />
      {isMobile && <FixedBottomNavigation />}

    </>
  );
}
