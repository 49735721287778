/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { baseWebServer } from 'src/api/baseWebServer';
// eslint-disable-next-line import/no-cycle
import { persistReducer } from 'redux-persist';
// eslint-disable-next-line import/no-cycle
import authSlice from './slices/authSlice';
// eslint-disable-next-line import/no-cycle
import assetsTransfer from './slices/assetsTransferSlice';
// eslint-disable-next-line import/no-cycle
import { giftoinsSlice } from './slices/giftoinsSlice';
// eslint-disable-next-line import/no-cycle
import { modalsSlice } from './slices/modalsSlice';
// eslint-disable-next-line import/no-cycle
import userSlice from './slices/userSlice';
import giftoinInfoViewSlice from './slices/giftoinInfoViewSlice';
import redeemSlice from './slices/redeemSlice';
import loginSlice from './slices/loginSlice';
import resellerSettingsSlice from './slices/resellerSettingsSlice';
import walletSlice from './slices/wallet';
import { notificationsSlice } from './slices/notificationsSlice';
import { inventorySlice } from './slices/inventorySlice';
// slices

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  version: 1,
  keyPrefix: 'redux-',
};
export const userPersistConfig = {
  key: 'root',
  storage,
  version: 1,
  keyPrefix: 'redux-',
};


const userPersistedReducer = persistReducer(userPersistConfig, userSlice);

const rootReducer = combineReducers({
  giftoins: giftoinsSlice.reducer,
  modals: modalsSlice.reducer,
  assetsTransfer,
  auth: authSlice,
  user: userPersistedReducer,
  giftoinView: giftoinInfoViewSlice,
  redeem: redeemSlice,
  login: loginSlice,
  inventory: inventorySlice.reducer,
  notifications: notificationsSlice.reducer,
  resellerSettings: resellerSettingsSlice,
  wallet: walletSlice,
  [baseWebServer.reducerPath]: baseWebServer.reducer,
});

export default rootReducer;
