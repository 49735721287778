import { Stack, Avatar, Typography, IconButton, Drawer, Tabs, Tab, Card, Grid, Alert } from "@mui/material";
import { ConnectionApiTypes, useGetConnectionsQuery } from "src/api/connectionApi";
import { DefaultIcons } from 'src/components/icons/defaults';




import type { IconButtonProps } from '@mui/material/IconButton';

import { m } from 'framer-motion';
import { useState, useCallback } from 'react';
import { useBoolean } from "src/hooks/use-boolean";
import { varHover } from "src/components/animate";
import Iconify from "src/components/iconify";
import { useTabs } from "src/hooks/use-tabs";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import Label from "src/components/label";
import useCopyToClipboard from "src/hooks/useCopyToClipboard";
import { useSnackbar } from "src/components/snackbar";


const getConnectionImage = {
    [ConnectionApiTypes.Connection.ConnectionTypes.Discord]: DefaultIcons.discord,
    [ConnectionApiTypes.Connection.ConnectionTypes.Roblox]: DefaultIcons.roblox,
    [ConnectionApiTypes.Connection.ConnectionTypes.Steam]: DefaultIcons.steam,
    [ConnectionApiTypes.Connection.ConnectionTypes.Twitter]: DefaultIcons.twitter,
}

interface IConnection {
    type: string;
    name: string;
    id: string;
    active: boolean;
    image: string;
    addedTimestamp?: number;
}

export function AccountConnectionsView() {
    // const theme = useTheme();

    const { data, isLoading } = useGetConnectionsQuery({});

    const availableConnections = Object.values(ConnectionApiTypes.Connection.ConnectionTypes);


    const connections: IConnection[] = availableConnections.map(c => {
        const existingConnection = data?.find(d => d.type === c);
        const image = getConnectionImage[c];
        return {
            type: c,
            active: !!existingConnection,
            name: existingConnection?.name ?? "",
            id: existingConnection?.id ?? "",
            image,
            addedTimestamp: existingConnection?.createdTimestamp,
        }
    }).filter(c => c.active);


    const { copy } = useCopyToClipboard();
    const { enqueueSnackbar } = useSnackbar();
    const onCopy = useCallback(
        (text: string) => {
            if (text) {
                enqueueSnackbar('Copied Discord ID!');
                copy(text);
            }
        },
        [copy, enqueueSnackbar]
    );


    return <>
        {isLoading ?
            <LoadingScreen />
            :
            <Stack direction="column" spacing={1.5} sx={{ mt: 3 }}>
                <Alert severity="info">
                    Connections are automatically imported when logging in via Discord
                </Alert>
                {connections?.map((connection) => (
                    <Stack direction="row" spacing={1.5} key={connection.id}>
                        <Card sx={{ width: '100%', p: 1, }} >
                            <Grid container p={1}>
                                <Grid item xs={3}>
                                    {/* <Avatar src={connection.image} alt={connection.type} /> */}
                                    <Iconify width={58} icon={connection.image} />
                                </Grid>
                                <Grid item xs={9}>
                                    <Stack direction="column" spacing={0.5}>
                                        <Typography variant="subtitle1">{connection.name}</Typography>
                                        {/* <Typography variant="caption" color={connection.active ? 'success.main' : 'error.main'}>
                                        Active: {connection.active ? 'Yes' : 'No'}
                                    </Typography> */}
                                        {/* {connection.active && ( */}
                                        <Typography variant="caption" textTransform="capitalize">{connection.type}</Typography>
                                        {/* )} */}


                                        {connection.type === "discord" &&
                                            <Label sx={{ cursor: "pointer", fontSize: 13 }} onClick={() => onCopy(connection.id)} endIcon={<Iconify icon={DefaultIcons.copy} />}>
                                                ID: {connection.id}
                                            </Label>}


                                    </Stack>
                                </Grid>
                            </Grid>
                        </Card>


                    </Stack>
                ))}
            </Stack>
        }

    </>

}