// routes
import { PATH_DASHBOARD, PATH_WALLET } from './routes/paths';
import { IBanner } from './types/banner';

// API
// ----------------------------------------------------------------------

export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';
export const WEB_SERVER_URL = process.env.REACT_APP_WEB_SERVER_URL ?? "";
export const AUTH_SERVER_URL = process.env.REACT_APP_AUTH_SERVER_URL;



export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAP_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_WALLET.home;

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92,
  H_MOBILE: 64,
  H_DESKTOP: 80,
  H_DESKTOP_OFFSET: 80,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
  W_VERTICAL: 280,
  W_MINI: 88,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};


// giftoin address
/** When creating product not on polygon/amoy need to transfer the assets to our wallet and not smart-contract */
export const BRIDGE_UNWRAP_DESTINATION = process.env.REACT_APP_BRIDGE_UNWRAP_DESTINATION ?? "";


// creator studio
export const MAX_ERC721_ASSETS = process.env.REACT_APP_MAX_ERC721_ASSETS ?? 3;
// eslint-disable-next-line radix
export const MAX_PRODUCT_CREATION_QUANTITY = parseInt((process.env.REACT_APP_MAX_PRODUCT_CREATION_QUANTITY ?? "10"));


// templates images
export const VALENTINE_IMAGE_URL = process.env.REACT_APP_TEMPLATE_VALENTINE_IMAGE || '';

// template paths
export const VALENTINE_TEMPLATE_PATH = process.env.REACT_APP_TEMPLATE_VALENTINE || '';


// ipfs

export const IPFS_GATEWAY = process.env.REACT_APP_IPFS_GATEWAY || "";




export const BANNERS_AUTO_PLAY = process.env.REACT_APP_BANNERS_AUTO_PLAY ? process.env.REACT_APP_BANNERS_AUTO_PLAY === "true" : true;

export const REACT_APP_GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || '';



export namespace GlobalConfig {

  export const RESET_PASSWORD_CONTINUE_URL = process.env.REACT_APP_RESET_PASSWORD_CONTINUE_URL ?? "https://wallet.giftoin.org/login";
  export const VERIFY_EMAIL_CONTINUE_URL = process.env.REACT_APP_VERIFY_EMAIL_CONTINUE_URL ?? "https://wallet.giftoin.org/login";

  export const REGISTER_PASSWORD_REGEX = process.env.REACT_APP_REGISTER_PASSWORD_REGEX ?? "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})";

  export const DEFAULT_WALLET_NAME = process.env.REACT_APP_DEFAULT_WALLET_NAME ?? "giftoin";

  export const GIFTOIN_TOS_LINK = process.env.REACT_APP_GIFTOIN_TOS_LINK ?? "https://giftoin.com/terms_of_service/";

  export const WALLET_CONNECT_PROJECT_ID = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID ?? "";

  export const REACT_APP_FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || 'gt-org-dev.firebaseapp.com';

  export const HOME_PAGE_MAX_ITEMS_LIST = 4;

  export const LOYALTY_PROGRAM_DEFAULT_IMAGE = process.env.LOYALTY_PROGRAM_DEFAULT_IMAGE || "https://firebasestorage.googleapis.com/v0/b/gt-org-prod.appspot.com/o/reseller-dashboard%2Floyalty%2Fcollections%2Fexamples%2Fdefault-collection-image.png?alt=media&token=4af99393-c332-4d6e-8657-e6bab42fdf35";

  export const MAINTENANCE_MODE = process.env.REACT_APP_MAINTENANCE_MODE === "true";

  export const DISCORD_CLIENT_ID = process.env.REACT_APP_DISCORD_CLIENT_ID ?? '1263516668660027492';

}


export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: GlobalConfig.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
