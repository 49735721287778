/* eslint-disable import/no-cycle */
import {
    createEntityAdapter,
    createSelector,
    createSlice,
    EntityId,
    PayloadAction,
    Update,
} from '@reduxjs/toolkit'
import { giftoinApi, IGetManyResponse } from 'src/api/giftoinApi';
import { Giftoin } from 'src/types/Giftoin';
import { RootState } from '../store';

type IGiftoin = Giftoin.IGiftoin;

const giftoinsAdapter = createEntityAdapter<IGiftoin>({
    selectId: (giftoin) => giftoin.id,
    sortComparer: (a, b) => (a.giftedDate - b.giftedDate),
})

export const giftoinsSlice = createSlice({
    name: 'giftoins',
    initialState: giftoinsAdapter.getInitialState(),
    reducers: {
        // Can pass adapter functions directly as case reducers.  Because we're passing this
        // as a value, `createSlice` will auto-generate the `bookAdded` action type / creator
        // bookAdded: giftoinsAdapter.addOne,
        updateGiftoin: giftoinsAdapter.setOne,
        setAllGiftoins: giftoinsAdapter.setAll,
        resetGiftoins: giftoinsAdapter.removeAll,
        removeGiftoin: giftoinsAdapter.removeOne,
    },
    extraReducers(builder) {
        builder.addMatcher(
            giftoinApi.endpoints.getMany.matchFulfilled,
            // Update the local giftoins cache
            (state, action: PayloadAction<IGetManyResponse>) => {
                // Check if its first time updating cache
                const isConnectedFirstTime = Object.keys(state.entities).length === 0;
                // const giftoins = action.payload.curWallet.pendingGiftoins.map(giftoin => {
                //     if (giftoin.randomizationSettings && giftoin.status !== "Gifted") {
                //         const card = giftoin.assets.ERC1155.length > 0 ? giftoin.assets.ERC1155[0] : undefined;
                //         giftoin.metadata.name = card?.metadata.name ?? giftoin.metadata.name;
                //     }
                //     return giftoin;
                // });

                if (isConnectedFirstTime) {
                    // First time - setting the giftoins data - overwriting the current empty [] giftoins entities
                    giftoinsAdapter.setMany(state, action.payload.curWallet.pendingGiftoins);

                } else {
                    // Need to overwrite the previous giftoins
                    giftoinsAdapter.setAll(state, action.payload.curWallet.pendingGiftoins);
                    // // Not first time - updating the current giftoins data saved in cache 
                    // const changes: Update<IGiftoin>[] = action.payload.curWallet.giftoins.map(giftoin => {
                    //     const entityState: Update<IGiftoin> = {
                    //         id: giftoin.publicKey,
                    //         changes: giftoin,
                    //     };
                    //     return entityState
                    // })
                    // giftoinsAdapter.updateMany(state, changes);
                }

            }
        )
    },

})

export const { updateGiftoin, removeGiftoin, resetGiftoins, setAllGiftoins } = giftoinsSlice.actions;
// export const giftoinsSelectors = giftoinsAdapter.getSelectors((state: any) => state.giftoins);

/* Can create a set of memoized selectors based on the location of this entity state
   By using the RootState from store we are using the giftoinsSlice created here
   
   This is crucial in order to use 
   */
export const giftoinsSelectors = giftoinsAdapter.getSelectors<RootState>(
    (state) => state.giftoins,
)

/**
 * 
 * @param id 
 * @returns 
 */
//! The create selector needs the root state in order to subscribe to the value
export const selectGiftoin = (id: string | EntityId) => createSelector((state: RootState) => giftoinsSelectors.selectById(state, id), giftoin => giftoin)
export const selectGiftoinsIds = createSelector((state: RootState) => giftoinsSelectors.selectIds(state), ids => ids)
export const selectAllGiftoins = createSelector((state: RootState) => giftoinsSelectors.selectAll(state), giftoins => giftoins);



