
import { AlertColor } from "@mui/material";

export enum View {
    Main = "Main",
    LoginEmailAndPassword = "LoginEmailAndPassword",
    RegisterEmailAndPassword = "RegisterEmailAndPassword",
    ResetPassword = "ResetPassword",
    LoginPhone="LoginPhone",
}


export type AlertMessage = {
    message: string;
    severity: AlertColor;
} | null;
