import { IEnvironment } from 'src/types/environment';
import { ObjectValues } from 'src/types/utils';
import { SupportedNetworks } from 'src/types/Network';
import { ReturnObjectGeneric } from '../types/org-logger/ReturnObject';
import { baseWebServer } from './baseWebServer';





export const assetApi = baseWebServer.injectEndpoints({
    endpoints: (builder) => ({
        getAssetsPoints: builder.query<TokenTypes.IPoint[], { accountId: string }>({
            query: (args) => ({
                url: `/api/${args.accountId}/assets/points`,
                method: 'GET',
                params: { ...args },
            }),
            providesTags: ['Assets_Points'],
            transformResponse: (baseQueryReturnValue: ReturnObjectGeneric<TokenTypes.IPoint[]>,
                meta: unknown,
                arg: unknown,
            ) => {
                if (!baseQueryReturnValue.data) return [];

                return baseQueryReturnValue.data
            },
        }),
        executeShopOffer: builder.mutation<ShopApiTypes.Offer.Order.CreateOne.IResponse, { offerId: string; accountId: string; pageId: string }>({
            query: (args) => ({
                url: `/api/${args.accountId}/shop/pages/${args.pageId}/offers/${args.offerId}/order`,
                method: 'POST',
            }),
            invalidatesTags: ['Shop', 'Inventory'],
            transformResponse: (baseQueryReturnValue: ReturnObjectGeneric<ShopApiTypes.Offer.Order.CreateOne.IResponse>,
                meta: unknown,
                arg: unknown,
            ) => baseQueryReturnValue.data as ShopApiTypes.Offer.Order.CreateOne.IResponse,
        }),



    }),
})

export const {
    useGetAssetsPointsQuery,
} = assetApi;



export namespace ShopApiTypes {


    export interface IPlacementSettings {
        position: number; // Determines the display order
    }
    export const statuses = {
        IN_CREATION: 'IN_CREATION',
        ACTIVE: 'ACTIVE',
        ARCHIVE: 'ARCHIVE',
    } as const;
    export type IStatus = typeof statuses[keyof typeof statuses];

    export namespace Offer {
        export interface IOffer {
            id: string;
            name: string;
            image: string;
            description: string;
            duration?: {
                start: {
                    date: number | null;
                    showBefore?: number; // Optional
                };
                end: {
                    date: number | null;
                    showAfter?: number; // Optional
                };
            };
            fromSet: {
                items: {
                    type: 'COIN' | 'CARD' | 'REWARD';
                    quantity: number;
                    id: string;
                    scope: 'RESELLER' | 'GLOBAL';
                    name: string;
                    image: string;
                }[];
                condition: 'OR' | 'AND'; // Indicates how to interpret the items within this set
            };
            toSet: {
                items: {
                    type: 'COIN' | 'CARD' | 'REWARD';
                    quantity: number;
                    id: string;
                    scope: 'RESELLER' | 'GLOBAL';
                    name: string;
                    image: string;
                }[];
                condition: 'OR' | 'AND'; // Indicates how to interpret the items within this set
            };
            status: 'IN_CREATION' | 'ACTIVE' | 'ARCHIVE'; // Offer lifecycle status
            stockStatus: 'IN_STOCK' | 'OUT_OF_STOCK'; // Stock availability status
            placementSettings: {
                position: number; // Determines the display order
            };
            stockSettings: {
                quantity: number | 'unlimited'; // Quantity available for the offer, or 'unlimited'
                userLimit?: {
                    limit: number; // Maximum number of times a user can execute the offer
                    interval?: 'daily' | 'weekly' | 'monthly'; // Optional interval for the user limit
                };
            };
            environment: IEnvironment;
            createdTimestamp: number;
            lastUpdatedByUserTimestamp: number | null;
        }

        export namespace GetOne {
            export namespace Request {
                export type IQuery = {
                    id: string;
                }
            }
            export type IResponse = IOffer | null;
        }


        export namespace GetAll {
            export type IResponse = IOffer[];
        }



    }
    export namespace Section {
        export interface ISection {
            id: string;
            name: string;
            description: string;
            placementSettings: {
                position: number; // Determines the display order
            };
            offers: Offer.IOffer[];
            createdTimestamp: number;
            lastUpdatedByUserTimestamp: number | null;
        }

        export namespace GetOne {
            export namespace Request {
                export type IQuery = {
                    id: string;
                }
            }
            export type IResponse = ISection | null;
        }


        export namespace GetAll {
            export type IResponse = ISection[];
        }



    }


    export namespace Offer {


        export namespace Order {
            const orderStatuses = {
                PROCESSING: 'PROCESSING',
                COMPLETED: 'COMPLETED',
                FAILED: "FAILED",
            } as const;
            export type IOrderStatus = ObjectValues<typeof orderStatuses>;

            type ISet = {
                items: {
                    type: 'COIN' | 'CARD' | 'REWARD';
                    quantity: number;
                    id: string;
                    scope: 'RESELLER' | 'GLOBAL';
                }[];
                condition: 'OR' | 'AND'; // Indicates how to interpret the items within this set
            }


            export namespace CreateOne {
                export namespace Request {
                    export type IBody = {
                    }
                }
                export type IResponse = { transactionId: string; status: "Completed" | "Failed" };
            }

        }
        export namespace Execute {
            export namespace Request {
                export type IParams = {
                    pageId: string;
                    offerId: string;
                    accountId: string;
                }
            }
            export type IResponse = boolean;
        }

    }

    export namespace Page {
        export namespace GetOne {

            export interface ISection extends Section.ISection {
                placementSettings: IPlacementSettings;
            }

            export namespace Request {
                export type IParams = {
                    pageId: string;
                    accountId: string;
                }
            }
            export type IResponse = {
                id: string;
                name: string;
                description: string;
                sections: ISection[];
                createdTimestamp: number;
                lastUpdatedByUserTimestamp: number | null;
                status: IStatus;
            } | null;
        }



    }




}




export namespace TokenTypes {
    export const contractTypes = {
        "ERC20": "ERC20",
        "ERC1155": "ERC1155",
    } as const;

    export type IContractType = ObjectValues<typeof contractTypes>;


    export interface IPoint {
        createdTimestamp: number;
        lastUpdateByUserTimestamp?: number;
        name: string;
        image: string;
        symbol: string;
        worthInUsd?: number;// How much does 1 of this coin worth in $ - to help the admin calculate & plan stuff
        contractAddress: string;
        network: SupportedNetworks;
        tokenId: string;
        contractType: IContractType;
        id: string;
        onChain: boolean;
        tradable: boolean;
        duration?: {
        }
        stats: {
            members: number;
            totalMinted: number;
        };
        environment: IEnvironment;
    }



}

