import { useCallback, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'src/redux/store';
import { Outlet, useNavigate } from 'react-router';

// components
import { updateResellerId, updateResellerName, updateResellerSettings } from 'src/redux/slices/resellerSettingsSlice';
import { settingsApi } from 'src/api/setttingsApi';
import LoadingScreen from '../components/loading-screen';
//

// ----------------------------------------------------------------------


/**
 * Check the reseller id exists on the backend
 * 
 * Fetch the reseller settings (texts & images)
 */
export default function ResellerGuard() {
  const { resellerId } = useParams();
  const [isFinished, setIsFinished] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (resellerId) {
      updateResellerName({ name: resellerId });

    }
  }, [resellerId])




  const getResellerSettings = useCallback(async (id: string) => {
    try {
      const result = await dispatch(settingsApi.endpoints.getSettings.initiate({ resellerName: id }));
      if (result.isError) {
        // Navigate to internal server error under the reseller path
        // Known reseller but error with settings
        navigate("../500", { relative: 'path' });
      } else if (result.data) {

        await dispatch(updateResellerSettings({ ...result.data.settings, name: id }));
        dispatch(updateResellerId({ id: result.data.resellerId }));
      } else {
        // Unknown reseller name -> url not exist
        navigate("/404");
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsFinished(true);
    }
  }, [dispatch, navigate])


  useEffect(() => {
    if (resellerId) {
      getResellerSettings(resellerId);
    }

  }, [getResellerSettings, resellerId])


  if (!resellerId) {
    return <Navigate to="/404" replace />;
  }

  if (!isFinished) {
    return <LoadingScreen />;

  }

  return <Outlet />;
};

