import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useGetAssetsPointsQuery } from "src/api/assetApi";
import { useAppSelector } from "src/redux/store";

interface IBalance {
    id: string;
    quantity: number;
    name: string;
    image: string;
}

export default function PointsBalances() {
    const accountId = useAppSelector(state => state.resellerSettings.resellerId);
    const { data: points } = useGetAssetsPointsQuery({ accountId });

    const inventoryPoints = useAppSelector(state => state.inventory.points);

    const [balances, setBalances] = useState<IBalance[]>([]);


    useEffect(() => {
        const newBalances: IBalance[] = points ?
            points.map(c => {
                const inventoryCoin = inventoryPoints.find(i => i.id === c.id);
                return { id: c.id, image: c.image, name: c.name, quantity: inventoryCoin?.quantity ?? 0 }

            })
            : [];

        setBalances(newBalances);
    }, [points, inventoryPoints])



    return <>
        {balances && balances.length > 0 &&
            <Stack display='flex' alignItems='center' justifyContent='center' component={Card} spacing={2} sx={{ pl: 2, pr: 2, pt: 1, pb: 1, backgroundColor: (theme) => theme.palette.background.neutral }}>
                {/* <Grid container display='flex' justifyContent='center' > */}

                {balances.map((coin, index) =>
                    // <Grid item xs={4}>
                    <Stack spacing={1} display='flex' alignItems='center' direction='row' key={index}>
                        <Typography variant="subtitle1">
                            {coin.quantity}
                        </Typography>
                        <Box width={32} component="img" alt={coin.name} src={coin.image ?? ""} sx={{
                            height: '100%',

                        }} />
                    </Stack>
                    // </Grid>
                )}

                {/* </Grid> */}


            </Stack>
        }
    </>

}