import { User } from "firebase/auth";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { userAuth } from "src/api/userAuth";
import { setSession } from "src/auth/utils";
import { web2Login } from "src/redux/slices/authSlice";
import { updateWalletView } from "src/redux/slices/userSlice";
import { store, useAppDispatch } from "src/redux/store";
import { extractStatusObjectFromError, handleError } from "src/utils/errors";





export default function useLogin() {

    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    // eslint-disable-next-line consistent-return
    const login = async (firebaseUser: User, resellerName?: string) => {
        try {

            if (firebaseUser) {
                const firebaseIdToken = await firebaseUser.getIdToken();
                // Save access token in local storage & initiate timer for current session
                setSession(firebaseIdToken);

                const emailAddress = firebaseUser.email ?? "";
                resellerName = resellerName ?? store.getState().resellerSettings.name

                // Attempt to login with user credentials and fetch its data
                const result: any = await store.dispatch(userAuth.endpoints.login.initiate({ emailAddress, resellerName }));

                if (!result) {
                    throw new Error("Configuration error - please refresh the site");
                }
                if (result.error) {
                    const errorStatus = extractStatusObjectFromError(result.error);
                    // if (errorStatus.errorCode === 5107) {
                    //     return false;
                    // }
                    // // User not found, meaning the user is not in whitelist
                    // if (errorStatus.errorCode === 5100) {
                    //     return false;
                    // };
                    if (errorStatus.errorCode === 500) {
                        throw new Error("Communication error with server - please try again later");
                    };
                    enqueueSnackbar(`User is not in whitelist`, { variant: 'warning' })

                    return false;
                };

                // Enforce the view for the user to see upon login to be the gifts view
                dispatch(updateWalletView("home"));

                dispatch(web2Login({ user: firebaseUser }));
                return true;

            }

            return false;


        } catch (error) {
            throw new Error(handleError(error) as string);
        }
    }


    return { login }

}